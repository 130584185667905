import {  useEffect } from 'react';

const Location = ({ onLocationChange }) => {

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    onLocationChange({ latitude, longitude });
                },
                (error) => {
                    console.log(error, "error")
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, [onLocationChange]);


    return null
};

export default Location;
