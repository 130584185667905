import React from "react";

const CategoryCard = ({ category, onCategoryClick,  activeCategory, BASE_URL }) => {
  // Fallback in case the category is undefined
  if (!category) {
    return null; // Prevent rendering if no valid category is provided
  }

  console.log(activeCategory,"activeCategory33333")
  return (
    
    <div
      className={`text-center cursor-pointer ${activeCategory === category._id ? "text-custom-blue" : "text-gray-900"
        }`}
      onClick={() => onCategoryClick(category._id)}
    >
      <div
        className={`flex m-auto items-center justify-center bg-gray-100 rounded-full p-2 w-32 h-32 ${activeCategory === category._id
            ? "shadow-[0_4px_15px_rgba(0,0,0,0.3)] border-[1px] border-custom-blue"
            : "shadow-lg"
          }`}
      >
        <img
          src={`${BASE_URL}/${category.image}`}
          alt={category.title || "Category"}
          className="rounded-full w-full h-full object-cover"
        />
      </div>
      <h6 className="pt-4">{category.title}</h6>
    </div>
    
  );
};

export default CategoryCard;



// import React from "react";

// const CategoryCard = ({category,categoriesData, onCategoryClick, activeCategory, BASE_URL }) => {
//   if (!category) {
//     return null;  
//   }
//   return (




//     <div className="">


//       <div className="grid grid-cols-12  animate-fadeInUp opacity-0" style={{ animationDelay: '0.5s' }} >
//         <div className='col-span-12'>
//           <div className="grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-10 xl:grid-cols-10 gap-4">


//             {categoriesData.map((category) => (
//               <div
//                 key={category._id}
//                 className={`col-span-6 lg:col-span-2 md:col-span-3 sm:col-span-4 text-center cursor-pointer
//                      ${activeCategory === category._id ? '  text-custom-blue ' : 'text-gray-900'}
//                        `}
//                 onClick={() => onCategoryClick(category._id)}
//               >
//                 <div className={`flex m-auto items-center justify-center bg-gray-100 rounded-full p-2 w-32 h-32
//       ${activeCategory === category._id ? 'shadow-[0_4px_15px_rgba(0,0,0,0.3)] border-[1px] border-custom-blue' : 'shadow-lg'}
//     `}>
//                   <img
//                     src={`${BASE_URL}/${category.image}`}

//                     className="rounded-full w-full h-full object-cover"
//                   />
//                 </div>
//                 <h6 className='pt-4'>{category.title}</h6>
//               </div>
//             ))}

//           </div>

//         </div>
//       </div>
//     </div>


//   );
// };

// export default CategoryCard;



