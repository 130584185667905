import React from "react";
import BG1 from "../../assets/images/AboutUs/bg1.png";
import LImg from "../../assets/images/img/privacy policy.png";
const Terms = () => {
  return (
    <>
      <div
        className="container mt-5 m-auto relative bg-no-repeat bg-cover rounded-lg text-white grid grid-cols-1 lg:grid-cols-2 items-center"
        style={{
          backgroundImage: `url(${BG1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Left Content Section */}
        <div className="p-6 sm:p-10 lg:p-12 m-auto text-center lg:text-left">
          <h2 className="  text-[28px] lg:text-[42px] sm:text-[32px] md:text-[32px] font-bold flex justify-center lg:justify-start">
            Terms & Condition
          </h2>
          <p className=" text-[22px] text-base   mt-4 mb-2">
            Get Rewarded For Doing Things You Love
          </p>
          <hr className="w-24 " />
        </div>

        {/* Right Image Section */}
        <div className="flex justify-center items-center p-6 sm:p-10 lg:p-12">
          <img src={LImg} alt="Privacy Policy" className=" " />
        </div>
      </div>
      <div className="container m-auto py-10">
        <h6 className="text-red-600 pb-5 text-xl">
          singhtek, accessible from https://singhtek.com/, one of our main
          priorities is the privacy of our visitors.
        </h6>
        <p className="text-[#857885]  mt-2">
         
          <br /> Welcome to{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span>. These Terms
          and Conditions govern your access to and use of the{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> website, mobile
          application, and all related services provided by{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> ("we", "us", or
          "our"). By accessing or using the{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> services, you
          agree to comply with and be bound by these Terms and Conditions, along
          with any applicable laws and regulations. If you do not agree to these
          terms, you should refrain from using our services.
        </p>
        <p className="text-[#857885]  mt-2">
          Acceptance of Terms By accessing or using the{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> platform, you
          acknowledge that you have read, understood, and agreed to comply with
          these Terms and Conditions. We may modify or update these terms from
          time to time, and the revised version will be posted on the{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span>
          website. Your continued use of our services after any changes to these
          terms constitutes your acceptance of the updated terms. Therefore, we
          encourage you to review this page regularly for any updates.
        </p>
        <p className="text-[#857885]   mt-2">
          Eligibility To use the services offered by{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span>, you must be of
          legal age in your jurisdiction and have the legal capacity to enter
          into a binding agreement. If you are under the age of 18 or otherwise
          not able to form legally binding contracts, you are not permitted to
          use our platform.
        </p>
        <p className="text-[#857885]   mt-2">
          Services Provided{" "}
          <span>
            <span className="  text-[#E73921]">Cash</span>
          </span>
          <span className=" text-[#025D89]">Fuse</span> offers a
          payment processing and rewards platform designed to facilitate
          transactions between users and merchants. Through our platform, users
          can make payments, earn cashback in the form of{" "}
          <span>
            <span className="  text-[#E73921]">Cash</span>
          </span>
          <span className="  text-[#025D89]">Fuse</span> Points, redeem
          points for discounts, and convert them into real money, subject to
          certain terms and conditions. Merchants can register on the platform
          to accept payments and offer cashback rewards to customers.
        </p>
        <p className="text-[#857885]   mt-2">
          Payment and Transaction Processing{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> facilitates
          transactions between users and merchants via various payment methods,
          including QR code payments and ATM card transactions. The platform is
          designed to ensure a seamless transaction experience, providing secure
          payment gateways and ensuring that payments are processed efficiently.
          However,{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> is not
          responsible for any transaction failures that may arise from external
          payment gateways or issues related to banking systems.
        </p>
        <p className="text-[#857885]   mt-2">
          Cashback and Rewards Users of{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> can earn{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> Points as
          cashback after completing eligible transactions with participating
          merchants. These points are not redeemable for cash directly unless
          converted according to the platform’s rules. The value and conditions
          of cashback offers vary depending on the merchant's promotions. Users
          may redeem accumulated points for discounts on future transactions or
          convert them into real money, subject to specific criteria such as
          meeting minimum redemption thresholds. Points may have an expiration
          date, and if not redeemed within the stipulated period, they may be
          forfeited.
        </p>
        <p className="text-[#857885]   mt-2">
          Merchant Registration and Responsibilities Merchants who wish to
          participate in the{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> platform must
          complete the required registration process, which includes providing
          necessary business details and completing KYC (Know Your Customer)
          procedures as per the regulations in place. Merchants are responsible
          for maintaining the accuracy and validity of the information provided.
          They must also ensure that the cashback offers or promotions
          advertised on the platform are genuine, legally compliant, and clearly
          communicated to users. Failure to adhere to these obligations may
          result in suspension or termination of the merchant account.
        </p>
        <p className="text-[#857885]  mt-2">
          Account Security and Access{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> strives to
          protect the security of users' data and personal information. We
          employ industry-standard encryption and security measures to safeguard
          sensitive data. However, users are also responsible for maintaining
          the confidentiality of their account details, including usernames and
          passwords, and for all activities that occur under their accounts. It
          is advised that users report any unauthorized access or security
          breaches immediately.
        </p>
        <p className="text-[#857885]   mt-2">
          Prohibited Activities Users and merchants are prohibited from engaging
          in fraudulent or unlawful activities on the{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> platform. This
          includes, but is not limited to, attempting to manipulate cashback
          rewards, conducting unauthorized transactions, or using the platform
          to defraud others.{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> reserves the
          right to suspend or terminate any account found to be involved in such
          activities, and may take legal action if necessary.
        </p>
        <p className="text-[#857885]   mt-2">
          Contact Us If you have any questions or concerns regarding these Terms
          and Conditions, please feel free to contact us:
          <br /> Email: [Insert Contact Email]
          <br /> Phone: [Insert Contact Phone Number]
          <br /> Address: [Insert Contact Address] <br />
          By using the{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> platform, you
          confirm that you have read, understood, and agreed to these Terms and
          Conditions.
        </p>
      </div>
    </>
  );
};

export default Terms;
