import React, { useState } from "react";
import BG1 from "../../../assets/images/AboutUs/bg.png";
import BG2 from "../../../assets/images/AboutUs/bg1.png";
import AboutusImage2 from "../../../assets/images/AboutUs/bg-side.png";
import AboutusImage3 from "../../../assets/images/AboutUs/bg-slide1.png";
import {
  MdOutlineStorefront,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import Button from "../../Common/Button";
import { Link } from "react-router-dom";
const AboutUs = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const images = [
    {
      src: BG1,
      heading: "Discover Your City",
      description: "Get Rewarded For Doing Things You Love",

      color: "rgba(231, 57, 33, 1)",
      LeftImg: AboutusImage2,
    },
    {
      src: BG2,
      heading: "Retail Campus",
      description: "Get Rewarded For Doing Things You Love",
      icon: <MdOutlineStorefront className="mr-2" />,
      color: "rgba(0, 142, 204, 1)",
      LeftImg: AboutusImage3,
    },
  ];

  const handleNext = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0 ? images.length - 1 : prevImage - 1
    );
  };

  return (
    <div className="container mx-auto px-4 relative py-20">
      {/* Background Image Section */}
      <div
        className="relative bg-no-repeat bg-cover rounded-lg text-white grid grid-cols-1 lg:grid-cols-2 items-center"
        style={{
          backgroundImage: `url(${images[currentImage].src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="p-20 lg:p-12 m-auto">
          <h2 className="font-['Roboto Slab'] text-5xl sm:text-6xl md:text-5xl font-bold flex items-center">
            {images[currentImage].icon}
            {images[currentImage].heading}
          </h2>
          <p className="font-['Roboto'] text-[15px] sm:text-[16px] mb-4 md:text-[18px] font-normal mt-4">
            {images[currentImage].description}
          </p>
          <Link to="/contactus">
            {" "}
            <Button
              type="button"
              className="border-white border  hover:border-white   mt-3 xl:mt-0 lg:mt-0 sm:mt-3 md:mt-3 hover:text-custom-red text-white font-bold py-2 px-4 rounded-lg hover:bg-white transition duration-300"
              text=" Contact Us"
            />
          </Link>
        </div>
        <div className="hidden lg:block">
          <img
            src={images[currentImage].LeftImg}
            alt=""
            className=" m-auto p-10 object-cover"
          />
        </div>

        {/* Navigation Buttons */}
        <button
          className="rounded-full border-8 border-white  flex items-center justify-center absolute left-0 transform -translate-x-1/2 top-1/2 -translate-y-1/2"
          style={{
            backgroundColor: "rgba(243, 249, 251, 1)",
            color: images[currentImage].color,
            width: "60px",
            height: "60px",
          }}
          onClick={handlePrev}
        >
          <MdKeyboardArrowLeft size={24} />
        </button>
        <button
          className="rounded-full border-8 border-white  flex items-center justify-center absolute right-0 transform translate-x-1/2 top-1/2 -translate-y-1/2"
          style={{
            backgroundColor: "rgba(243, 249, 251, 1)",
            color: images[currentImage].color,
            width: "60px",
            height: "60px",
          }}
          onClick={handleNext}
        >
          <MdKeyboardArrowRight size={24} />
        </button>
      </div>

      {/* About Us Section */}
      <section className="font-['Roboto'] mt-8 md:mt-16">
        <h3 className="text-[#025D89] font-semibold text-2xl md:text-[35px]">
          About Us
        </h3>
        <p className="text-[#E73921] font-medium py-2 md:py-4 text-lg">
          Discovery and savings for local shopping. Food, fashion, beauty,
          pharmacy, and more.
        </p>
        <p className="text-[#857885] font-light text-base md:text-lg">
          Welcome to{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span>, a
          revolutionary payment and referral platform designed to enhance your
          shopping and transaction experience. At{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span>, we aim to
          bridge the gap between merchants and consumers by offering an
          innovative and secure way to make payments, earn rewards, and redeem
          exclusive cashback offers. Whether you're paying through a QR code or
          using the
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> ATM card, our
          platform provides a seamless, user-friendly experience for both users
          and merchants.
        </p>

        <p className="text-[#857885] font-light text-base md:text-lg mt-2">
          Our journey began with a simple yet powerful vision: to empower
          consumers with a rewarding payment experience while providing
          merchants with a new avenue to increase customer engagement and sales.
          With{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span>, every
          transaction becomes an opportunity to earn. Users can collect cashback
          in the form of{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> Points after
          making payments at participating merchants, which can later be
          redeemed for exclusive discounts or converted into real money.
        </p>
        <p className="text-[#857885] font-light text-base md:text-lg mt-2">
          For merchants,{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> offers a unique
          platform that allows them to onboard easily and engage with a broader
          customer base. By offering cashback rewards, merchants can incentivize
          customer loyalty, increase foot traffic, and boost sales. Our platform
          ensures that all merchant transactions are transparent, with a
          detailed record of payments and
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> Points awarded
          to users.{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> is not just
          about financial transactions; it’s about building a community where
          users can benefit from the rewards of their everyday purchases. We
          provide a secure, fast, and easy-to-use platform that supports
          multiple payment methods, ensuring that every user’s payment needs are
          met with convenience.
        </p>
        <p className="text-[#857885] font-light text-base md:text-lg mt-2">
          Our goal is to create an ecosystem where everyone wins — consumers
          save money and enjoy exciting rewards, while merchants grow their
          businesses with the support of our innovative tools. The{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span>
          experience is all about trust, transparency, and innovation, ensuring
          that our users and merchants have the best possible experience every
          time they use the platform. Whether you're looking for a better way to
          make payments or seeking to offer your customers exciting rewards,
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> is the perfect
          solution for you. Join us today and experience a world where every
          payment is more rewarding.
        </p>
      </section>
    </div>
  );
};

export default AboutUs;
