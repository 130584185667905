import React, { useEffect, useState } from "react";
import Button from "../Common/Button";
import useNetworkServices from "../../Networking/baseAPIManager";
import { useNavigate } from "react-router-dom";
import { FaFileUpload } from "react-icons/fa";

const MyBusiness = ({
  profileData,
  onImageChange,
  profileImage,
  onProfileUpdate,
}) => {
  const { UpdateProfile, Categorylist } = useNetworkServices();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [fileName, setFileName] = useState("");
  const [workingHour, setWorkingHour] = useState("");
  const [brandFileName, setBrandFileName] = useState("");
  const [formData, setFormData] = useState({
    shopImage: [{}],
    shopName: "",
    gstNumber: "",
    brandImage: "",
    workingHours: "",
    business_email: "",
    business_mobileNo: "",
    category: "",
    businessAddress: "",
    businessPinCode: "",
    businessCity: "",
    businessState: "",
    // image: profileImage,
    aboutUs: "",
  });

  console.log(formData, "formData==>");
  const [error, setErrors] = useState({});

  useEffect(() => {
    if (profileData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        businessLogo: profileData?.businessDetils?.logo || "",
        shopName: profileData?.businessDetils?.shopName || "",
        workingHours: profileData?.businessDetils?.workingHours || "",

        gstNumber: profileData?.businessDetils?.gstNumber || "",
        brandImage: profileData?.businessDetils?.brandImage || "",
        business_email: profileData?.businessDetils?.business_email || "",
        business_mobileNo: profileData?.businessDetils?.business_mobileNo || "",
        category: profileData?.businessDetils?.category || "",
        businessAddress: profileData?.businessDetils?.businessAddress || "",
        businessPinCode: profileData?.businessDetils?.businessPinCode || "",
        businessCity: profileData?.businessDetils?.businessCity || "",
        businessState: profileData?.businessDetils?.businessState || "",
        aboutUs: profileData?.businessDetils?.aboutUs || "",
        // image: profileImage,
      }));

      setWorkingHour(
        profileData?.businessDetils?.workingHours
          ? profileData.businessDetils.workingHours.split("\\").pop()
          : ""
      );
      setBrandFileName(profileData?.businessDetils?.brandImage);
      setFileName(profileData?.businessDetils?.shopImage);
    }
  }, [
    profileData,
    // , profileImage
  ]);

  console.log(
    profileData?.businessDetils?.workingHours,
    profileData,
    "profileworks===>"
  );
  useEffect(() => {
    const fatchCategory = async () => {
      try {
        const response = await Categorylist();
        if (response?.data?.data) {
          setCategories(response?.data?.data);
          console.log(response, "response22==>");
        }
      } catch (error) {
        console.error("category error", error);
      }
    };

    fatchCategory();
  }, []);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.workingHours)
      newErrors.workingHours = "working Hours is required";
    if (!formData.shopName) newErrors.shopName = "Shop Name is required";
    if (!formData.brandImage) newErrors.brandImage = "Tin Number is required";
    if (!formData.gstNumber) newErrors.gstNumber = " GST Number is required";
    if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        formData.business_email
      )
    )
      newErrors.business_email = "Invalid email businessAddress";
    if (!formData.business_mobileNo) {
      newErrors.business_mobileNo = "Phone Number is Required";
    }
    if (!formData.businessAddress)
      newErrors.businessAddress = "businessAddress is required";
    if (!formData.category) newErrors.category = "Select Category ";
    if (!/^\d{6}$/.test(formData.businessPinCode))
      newErrors.businessPinCode =
        "businessPinCode must be a valid 6-digit number";
    if (!formData.businessCity)
      newErrors.businessCity = "businessCity is required";
    if (!formData.businessState)
      newErrors.businessState = "businessState is required";
    if (!formData.aboutUs) newErrors.aboutUs = "AboutUs is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
    if (name === "image" && files) {
      onImageChange(files[0]);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const data = new FormData();
      Object.keys(formData).forEach((key) => {
        data.append(key, formData[key]);
      });
      formData.shopImage.forEach((file) => {
        data.append("shopImage", file);
      });
      try {
        const response = await UpdateProfile(data);

        if (response.data && response.data.photo) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            image: response.data.photo,
          }));
          onImageChange(response.data.photo);
        }
        console.log(response.data, "ResponseData");
        onProfileUpdate();
        navigate("/MyProfile#bank-details");
      } catch (error) {
        console.error("Update Profile error:", error);
      }
    }
  };

  const handleFileChange = (e) => {
    const { name, value, files } = e.target;

    if (files) {
      const selectedFiles = Array.from(files);
      if (selectedFiles.length > 5) {
        setErrors({
          ...error,
          shopImage: "You can only upload a maximum of 5 files.",
        });
        return;
      }

      const names = selectedFiles.map((file) => file.name);
      setFileName(names);
      setFormData((prevData) => ({ ...prevData, shopImage: selectedFiles }));
      return;
    }

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const [showTimePicker, setShowTimePicker] = useState(false);

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      workingHours: {
        ...prevData.workingHours,
        [name]: value,
      },
    }));
  };
  const handleBrandImageChange = (e) => {
    const { files } = e.target;

    if (files && files.length > 0) {
      const selectedFile = files[0]; // Get the first selected file
      setBrandFileName(selectedFile.name); // Set the name of the selected file
      setFormData((prevData) => ({ ...prevData, brandImage: selectedFile }));
    }
  };
  const handleInputClick = () => {
    setShowTimePicker(true); // Show the time inputs when the input is clicked
  };

  const handleTimeSelection = () => {
    setShowTimePicker(false); // Hide the time pickers after selection
  };

  const handleCancel = () => {
    setFormData({
      shopName: "",
      gstNumber: "",
      brandImage: "",
      business_email: "",
      business_mobileNo: "",
      category: "",
      businessAddress: "",
      businessPinCode: "",
      businessCity: "",
      businessState: "",
      // profileImage: "",
    });
    navigate("/MyProfile");
  };

  const inputFields = [
    { name: "category", type: "select", label: "Category" },
    {
      name: "shopName",
      type: "text",
      label: "Shop Name",
      placeholder: "Shop Name",
    },
    // { name: 'brandImage', type: 'text', label: 'Brand Name', placeholder: 'Brand Name' },
    {
      name: "gstNumber",
      type: "tel",
      label: "GST Number",
      placeholder: "GST Number",
    },
    {
      name: "business_email",
      type: "email",
      label: "Email",
      placeholder: "Email",
    },
    {
      name: "business_mobileNo",
      type: "tel",
      label: "Phone Number",
      placeholder: "Phone Number",
    },
    {
      name: "businessAddress",
      type: "text",
      label: "Address",
      placeholder: "Address",
    },
    {
      name: "businessPinCode",
      type: "tel",
      label: "Pincode",
      placeholder: "Pincode",
    },
    { name: "businessCity", type: "text", label: "City", placeholder: "City" },
    {
      name: "businessState",
      type: "text",
      label: "State",
      placeholder: "State",
    },
    { name: "aboutUs", type: "text", label: "AboutUs", placeholder: "AboutUs" },
  ];

  return (
    <div className="pt-8">
      <form className="space-y-4 md:space-y-6" onSubmit={handleFormSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
          <div>
            <label
              htmlFor="businessLogo"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
            >
              Shop Image
            </label>

            <div className="w-full">
              <input
                type="file"
                name="businessLogo"
                id="businessLogo"
                className="hidden"
                multiple
                onChange={handleFileChange}
              />
              <label
                htmlFor="businessLogo"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 block w-full cursor-pointer flex items-center justify-between"
              >
                <span className="flex items-center">
                  {fileName.length > 0 ? fileName.join(", ") : "Choose file"}
                </span>
                <FaFileUpload className="mr-2" />
              </label>
            </div>
            {error.businessLogo && (
              <div className="text-red-500 text-sm">{error.businessLogo}</div>
            )}
          </div>
          <div>
            <label
              htmlFor="brandImage"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
            >
              Brand Image
            </label>

            <div className="w-full">
              <input
                type="file"
                name="brandImage"
                id="brandImage"
                className="hidden"
                onChange={handleBrandImageChange}
              />
              <label
                htmlFor="brandImage"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 block w-full cursor-pointer flex items-center justify-between"
              >
                <span className="flex items-center">
                  {brandFileName ? brandFileName : "Choose file"}
                </span>
                <FaFileUpload className="mr-2" />
              </label>
            </div>
            {error.brandImage && (
              <div className="text-red-500 text-sm">{error.brandImage}</div>
            )}
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900">
              View Shop Hour
            </label>
            <input
              type="text"
              name="workingHours"
              onClick={handleInputClick}
              readOnly
              value={`${formData.workingHours.from || workingHour}`}
              placeholder="Select shop hours (e.g., 09:00 AM to 06:00 PM)"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-pointer"
            />

            {showTimePicker && (
              <div className="absolute bg-white border border-gray-300 p-4 rounded-lg mt-2 z-10">
                <div className="flex gap-4">
                  <div className="w-1/2">
                    <label className="block text-sm font-medium text-gray-900">
                      From
                    </label>
                    <input
                      type="time"
                      name="from"
                      value={formData.workingHours.from}
                      onChange={handleTimeChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="block text-sm font-medium text-gray-900">
                      To
                    </label>
                    <input
                      type="time"
                      name="to"
                      value={formData.workingHours.to}
                      onChange={handleTimeChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                  </div>
                </div>
                <button
                  type="button"
                  onClick={handleTimeSelection}
                  className="mt-2 bg-blue-500 text-white py-1 px-4 rounded-lg"
                >
                  Apply
                </button>
              </div>
            )}
          </div>
          {inputFields.map((field) => (
            <div key={field.name}>
              <label
                htmlFor={field.name}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
              >
                {field.label}
              </label>
              {field.type === "select" ? (
                <select
                  name={field.name}
                  id={field.name}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                  value={formData[field.name]}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select Category
                  </option>
                  {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.title}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={field.type}
                  name={field.name}
                  id={field.name}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                  placeholder={field.placeholder}
                  value={formData[field.name]}
                  onChange={handleChange}
                  min={field.min}
                  maxLength={field.maxLength}
                />
              )}
              {error[field.name] && (
                <div className="text-red-500 text-sm">{error[field.name]}</div>
              )}
            </div>
          ))}
        </div>

        <div className="xl:flex lg:flex flex justify-end gap-4 pt-5 xl:pt-0 lg:pt-0">
          <Button
            type="button"
            className="border-custom-red border hover:border-custom-red w-full mt-3 xl:mt-0 lg:mt-0 sm:mt-3 md:mt-3 hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
            text="Cancel"
            onClick={handleCancel}
          />
          <Button
            type="submit"
            className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
            text="Save Changes"
          />
        </div>
      </form>
    </div>
  );
};

export default MyBusiness;
