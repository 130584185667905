import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sucessImg from "../../assets/images/dashboardImg/success.gif";
const toastId = "redeemCashToast";

export const showToast = (message, type = "default", customClass = "") => {
  const toastTypes = {
    success: () => toast.success(message, { className: `${customClass} bg-green-500 text-white` }),
    error: () => toast.error(message, { className: "bg-blue-500 text-white" }),
    info: () => toast.info(message, { className: "bg-blue-500 text-white" }),
    warning: () => toast.warn(message, { className: "bg-yellow-500 text-black" }),
    default: () => toast(message, { className: "bg-gray-800 text-white" }),

    complete: () =>
      toast(
        <div className="flex flex-col items-center">
          <img src={sucessImg} alt="check" className="w-40 h-40 mb-2" />
          <span>{message}</span>
        </div>,

        {
          className: `${customClass} bg-blue-500 text-white text-center p-4 rounded-xl shadow-lg`,
          style: {
            position: "fixed",
            top: "50%",
            left: "50%",
            backgroundColor: "rgb(2 93 137)",
            color: "white",
            fontSize: "12px",
          },
        }
      ),
  };

  (toastTypes[type] || toastTypes.default)();
};

export const Toastify = () => {
  return (
    <ToastContainer
      position="top-right"
      // autoClose={1000}
      // hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      // pauseOnFocusLoss
      // draggable
      pauseOnHover
      theme="colored"
    />
  );
};
