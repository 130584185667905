import React,{useState,useEffect}  from 'react'
import { FaStar } from "react-icons/fa6"; 
import { IoLocationOutline } from "react-icons/io5";
import CashFuseCard from '../../Common/Cards/CahsFuseCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import OfferImg1 from '../../../assets/images/home/offer1.png';
import OfferImg2 from '../../../assets/images/home/offer22.png';
import OfferImg3 from '../../../assets/images/home/offer33.png';
import { useLocation } from 'react-router-dom';
import { FaPhoneAlt } from "react-icons/fa"; 
import AllReviews from './AllReview';  
const ShopDetail = () => {
    const location = useLocation(); 
    console.log(location, 'locationfggfd')
    const BASE_URL = "https://liveuat.com:5438";
    const {
        shopName,
        workingHour,
        PhNumber,
        termsMerchant,
        offerAboutUS, Address,
        TermsMerchant, storeOffer,
        shopImages,
        AboutUsMErchant,
        Rating,
        aboutUsMerchant,
        AboutUsOffer,
        termsOffer,
        TermsOffer
    } = location.state || {}; 
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const offerImages = [OfferImg1, OfferImg2, OfferImg3];







 

    const uniqueStoreOffers = Array.isArray(storeOffer)
        ? storeOffer.filter((offer, index, self) =>
            index === self.findIndex((o) => o.offerTitle === offer.offerTitle)
        )
        : []; // Default to an empty array if storeOffer is not an array

 
    return (
        <>

            <section className='py-20'>
                <div className='container m-auto'>
                    <div className="grid grid-cols-12  ">
                        <div className='col-span-12 '>
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                {shopImages && shopImages.length > 0 ? (
                                    shopImages.map((image, index) => (
                                        <img
                                            key={index}
                                            src={`${BASE_URL}/${image}`}
                                            alt={`Shop Image ${index + 1}`}
                                            style={{ width: "100%", height: "100%", objectFit: 'cover' }}
                                        />
                                    ))
                                ) : (
                                    <p>No images available.</p>
                                )}
                            </div>
                        </div>
                        {/* Additional content or components */}
                    </div>
                </div>
            </section>




            <section className='pb-10'>
                <div className='container m-auto p-4 bg-white shadow-md rounded-xl'>
                    <div className=" ">
                        <div className="grid grid-cols-12 gap-x-4">
                            <div class="col-span-12 xl:flex lg:flex md:flex sm:flex justify-between">
                                <div className="col-span-6">
                                    <h2 className='font-bold text-custom-blue lg:text-[40px] text-[35px] md:text-[45px]  animate-fadeInLeft opacity-100'

                                        style={{ animationDelay: '0s' }}> {shopName}</h2>
                                    {/* <h6 className='text-gray-400 text-lg'>Vision Alta Men’s Shoes</h6> */}

                                    <p className='text-gray-400 text-lg pt-2'><span className='text-gray-600'>Address:-</span> {Address}  </p>

                                </div>
                                <div className="col-span-6">
                                    <span className='flex text-[#EDB310] items-center pt-2'><FaStar className='me-1' /> {Rating} </span>
                                    <p className="mt-2   text-gray-400 text-[20px]">
                                        Shop  Time
                                        <span className="text-[#E73921] font-semibold"> {workingHour} </span>
                                    </p>
                                    <div className='flex gap-3 pt-3'>

                                        <span className='flex items-center text-sm text-gray-400 border-[.5px] border-gray p-1'><IoLocationOutline className='text-custom-blue me-2' /> 2.5 km</span>
                                        <span className='flex items-center text-sm text-gray-400 border-[.5px] border-gray p-1'><FaPhoneAlt className='text-custom-blue me-2 ' />  {PhNumber}</span>
                                       
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div>
                        <div className="container m-auto   pt-10">
                            <div className="grid grid-cols-12 gap-3">
                                <div className="col-span-6">
                                    <div>
                                        <h2 className='  text-gray-700 lg:text-[26px] text-[25px] md:text-[25px]'>About Us </h2>
                                        <p className='text-gray-400 text-[14px] pt-2'>{AboutUsMErchant ? AboutUsMErchant :""}, {aboutUsMerchant }</p>
                                    </div>


                                </div>

                                <div className="col-span-6">
                                    <div className=' '>
                                        <h2 className='  text-gray-700 lg:text-[26px] text-[25px] md:text-[25px]'>Terms & Conditions  </h2>
                                        <p className='text-gray-400 text-[14px] pt-2'>{TermsMerchant ? TermsMerchant:""} ,{termsMerchant}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>



            <section>
                <div className="container  mx-auto">


                    <div className="col-span-12">
                        <AllReviews />
                    </div>
                  
                </div>
            </section>


            <div className="container m-auto xl:gap-6 lg:gap-6 items-center pb-20">
                {uniqueStoreOffers.length === 1 ? (
                    // Render a single card if there's only one offer, with 30% width
                    <div className="p-2 w-[40%]">
                        <CashFuseCard
                            shopName={shopName}
                            offerName={uniqueStoreOffers[0]?.offerTitle || "Offer Title"}
                            offerAmount={uniqueStoreOffers[0]?.storeOffer || "Offer Amount"}
                            backgroundImage={offerImages[0]}
                        />
                    </div>
                ) : uniqueStoreOffers.length > 1 ? (
                    // Render a carousel if there are multiple offers
                    <Slider {...settings}>
                        {uniqueStoreOffers.map((merchant, index) => (
                            <div key={merchant.merchantId?._id || index} className="p-2">
                                <CashFuseCard
                                    shopName={shopName}
                                    offerName={merchant?.offerTitle || "Offer Title"}
                                    offerAmount={merchant?.storeOffer || "Offer Amount"}
                                    backgroundImage={offerImages[index % offerImages.length]}
                                />
                            </div>
                        ))}
                    </Slider>
                ) : (
                    // Display message if no offers are available
                    <p>No offers available at the moment.</p>
                )}
            </div>



            <section>
                <div className="container m-auto pb-20 ">
                    <div className="grid grid-cols-12 gap-3">
                        <div className="col-span-6">
                            <div>
                                <h2 className='  text-gray-700 lg:text-[26px] text-[25px] md:text-[25px]'>About Us </h2>
                                <p className='text-gray-400 text-[14px] pt-2'>{offerAboutUS}, {AboutUsOffer}</p>
                            </div>


                        </div>

                        <div className="col-span-6">
                            <div className=' '>
                                <h2 className='  text-gray-700 lg:text-[26px] text-[25px] md:text-[25px]'>Terms & Conditions  </h2>
                                <p className='text-gray-400 text-[14px] pt-2'> {TermsOffer}, {termsOffer}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ShopDetail;
