import React, { createContext, useEffect, useState } from 'react';
import useNetworkServices from '../../Networking/baseAPIManager';

// Create the ProfileContext
export const ProfileContext = createContext();

// ProfileProvider Component
export const ProfileProvider = ({ children }) => {
    const { GetProfile } = useNetworkServices();
  const [profileValue, setProfileValue] = useState({
    profileImage: null,
    profileName: '',
  });

  const fetchProfileData = async () => {
    try {
      const response = await GetProfile();
      const { profileImage, firstName, lastName, businessDetils } = response.data.data;
      const shopName = businessDetils?.shopName || '';
      setProfileValue({
        profileImage,
        profileName: `${firstName} ${lastName || ''}`,
      
      });
      localStorage.setItem('profileImage', profileImage);
      localStorage.setItem('profileName', `${firstName} ${lastName || ''}`);
      localStorage.setItem('shopName', shopName);
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);;

  return (
    <ProfileContext.Provider value={{ profileValue }}>
      {children}
    </ProfileContext.Provider>
  );
};
