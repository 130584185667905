import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

const Brands = ({ TopOfferData }) => {
    const merchants = TopOfferData?.merchant || [];
    const BASE_URL = "https://liveuat.com:5438";

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 340,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="lg:pb-20">
            <div className="container m-auto">
                <div className="lg:mb-10 p-4 text-center lg:p-0">
                    <h2 className="font-bold text-custom-blue lg:text-[40px] text-[30px] md:text-[35px]">
                        Top Brands
                    </h2>
                    {/* <p className="text-sm sm:text-base lg:text-lg text-gray-400 pt-1">
                        Up To <span className="text-yellow-500">30%</span> off on brands
                    </p> */}
                </div>


                <Slider {...settings}>
                    {merchants.map((merchant) => {
                        const brandImg = merchant?.businessDetils?.brandImage
                            ? `${BASE_URL}/${merchant.businessDetils.brandImage}`
                            : ''; // Provide a fallback image URL here if necessary.

                        return (

                            <div className='mx-2'>
                                <div
                                    key={merchant._id}
                                    className="items-center justify-center rounded-md m-3 h-36 bg-white shadow-custom"
                                >
                                    <img
                                        src={brandImg}
                                        alt={merchant?.businessDetils?.shopName || 'Brand Image'}
                                        className="w-full h-full"
                                    />
                                </div>

                            </div>
                        );
                    })}
                </Slider>
            </div>
        </section>
    );
};

export default Brands;
