import React, { useEffect, useState } from "react";
import Banner from "./Home/Banner";
import CashFuseStore from "./Home/CashFuseStore";
import CashFusePoint from "./Home/CashFusePoint";
import EOffer from "./Home/EOffer";
import Brands from "./Home/Brands";
import TrendingStore from "./Home/TrendingStore";
import KidsFashion from "./Home/KidsFashion";
import AppDownload from "./Home/AppDownload";
import { Testimonial } from "./Home/Testimonial";
import useNetworkServices from "../../Networking/baseAPIManager";


const Home = () => {

  const { GetTopOffer } = useNetworkServices()
  const [TopOfferData, setTopOfferData] = useState({
    offers: [],
    merchant: [],
    userLocation: { lat: 0, long: 0 },
  });
  console.log(TopOfferData, "TopOfferData11")

  const fatchOfferData = async () => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const lat = position.coords.latitude;
            const long = position.coords.longitude;

            console.log("Current Latitude:", lat);
            console.log("Current Longitude:", long);

            // Pass lat and long as params
            const response = await GetTopOffer({ params: { lat, long } });

            const offersData = response.data.data.offers;
            const merchantData = response.data.data.merchant;

            setTopOfferData({
              offers: offersData,
              merchant: merchantData,
              userLocation: { lat, long },
            });
            console.log(merchantData, "merchantData");
            console.log(offersData, "offersData");
          },
          async (error) => {
            console.error("Error fetching geolocation:", error);

            // Fallback coordinates
            const defaultLat = 23.48789;
            const defaultLong = 74.65585;

            // Call GetTopOffer with default location
            const response = await GetTopOffer({ params: { lat: defaultLat, long: defaultLong } });

            const offersData = response.data.data.offers;
            const merchantData = response.data.data.merchant;
            setTopOfferData({
              offers: offersData,
              merchant: merchantData,
              userLocation: { lat: defaultLat, long: defaultLong },
            });

            console.log(merchantData, "merchantData");
            console.log(offersData, "offersData");
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    } catch (error) {
      console.error("Error fetching offer data:", error);
    }
  };



  useEffect(() => {
    // Create an async function inside useEffect
    const fetchData = async () => {
      await fatchOfferData();
    };

    fetchData();  // Call the async function
  }, []);


  return (
    <>
      <Banner />
      <CashFuseStore TopOfferData={TopOfferData} />
      <CashFusePoint />
      <EOffer TopOfferData={TopOfferData} />
      <Brands TopOfferData={TopOfferData} />
      <AppDownload />
      <TrendingStore TopOfferData={TopOfferData} />
      <KidsFashion TopOfferData={TopOfferData} />
      <Testimonial />
    </>
  );
};
export default Home;
