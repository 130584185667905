import React, { useState, useEffect, useCallback, useMemo } from "react";

const Tabs = ({ tabs, }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [activeTitle, setActiveTitle] = useState("")

    // let paths = {};
    // tabs.forEach((tab, index) => {
    //     paths[tab.title.replace(" ", "-").toLowerCase()] = index;
    // });

    const paths = useMemo(() => {
        const newPaths = {};
        tabs.forEach((tab, index) => {
            newPaths[tab.title.replace(" ", "-").toLowerCase()] = index;
        });
        return newPaths;
    }, [tabs]);

    const helperFunc = useCallback(() => {
        let hashPath = window.location.hash.replace("#", "");
        if (paths[hashPath] !== undefined) {
            return paths[hashPath];
        } else {
            return 0;
        }
    }, [paths]);

    useEffect(() => {
        const handleHashChange = () => {
            setActiveTab(helperFunc());
        };

        window.addEventListener('hashchange', handleHashChange);

        // Set the initial active tab based on the current hash
        setActiveTab(helperFunc());

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [helperFunc]);

    useEffect(() => {
        setActiveTitle(tabs[activeTab]?.title);
    }, [activeTab, tabs]);

    const handleTabClick = (index) => {
        setActiveTab(index);
        const tabTitle = tabs[index].title.replace(" ", "-").toLowerCase();
        window.location.hash = `#${tabTitle}`;
    };

    return (
        <div className="mt-3 lg:p-6 md:p-6">
            <ul className=" gap-10 border-b  sm:flex xl:flex lg:flex md:flex">
                {tabs &&
                    tabs.map((tab, index) => (
                        <li
                            key={index}
                            className={`cursor-pointer pb-2 p-3 ${activeTab === index ? "border-b-2 border-custom-red text-custom-red bg-custom-red2" : "text-gray-500"
                                }`}
                            onClick={() => handleTabClick(index)}
                        >
                            <a
                                className={`block ${activeTab === index ? "text-custom-red bg-custom-red2" : "text-gray-500"
                                    }`}
                                aria-current="page"
                                href={`#${tab.title.replace(" ", "-").toLowerCase()}`}
                            >
                                {tab.title}
                            </a>
                        </li>
                    ))}
            </ul> 
            <div className="tab-content">{tabs[activeTab]?.content}</div>
        </div>
    );
};

export default Tabs;
