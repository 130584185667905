import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Common/Button";
import DefaultLogo from "../../../assets/images/img/various.png";


const StoreCard = ({ shopName, storeOffer, address, distance, logo, link, buttonText, state, Ratings, star, }) => {

    const starColor = Ratings > 0 ? "text-yellow-500" : "text-gray-400";

    return (
        <div className="bg-white shadow rounded overflow-hidden border mx-3">
            <div className="flex justify-center">
                <img
                    src={logo || DefaultLogo}
                    alt={shopName}
                    className="w-full h-40 object-cover rounded-xl"
                />
            </div>
            <div className="p-4 rounded-lg">
                <div className="flex justify-between items-center">
                    <h4 className="text-[14px] font-semibold text-gray-800">{shopName}</h4>
                    <p className="text-gray-400 text-[13px]">
                        Save
                        <span className="text-custom-orenge ps-1 font-semibold">{storeOffer}</span> CashFuse
                    </p>
                </div>

                <div className="flex justify-between items-center">

                    <p className="text-gray-400 text-[12px] mt-2">{address}</p>

                    <span className={`text-gray-400 text-[12px] flex items-center gap-1 ${starColor}`}>
                        {star} {Ratings}
                    </span>
                </div>

                <div className="flex justify-between items-center mt-2">
                    <span className="text-[13px] text-gray-400">  Distance: {distance} km</span>
                    <Link to={link} state={state}>
                        <Button
                            text={buttonText}
                            className="bg-custom-red text-white text-[11px] py-2 px-4 rounded hover:bg-custom-orenge transition duration-300"
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default StoreCard;
