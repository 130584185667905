import React, { useState } from 'react'
import CaseImg from '../../../assets/images/home/bill.jfif'
import CashImgMid from '../../../assets/images/home/cpb.png'
import PointBg from '../../../assets/images/home/pointBg.png'
import Arrow1 from '../../../assets/images/home/Arrow 1.png'
import Arrow2 from '../../../assets/images/home/Arrow 2.png'

import shop from '../../../assets/images/home/shop.jfif'
import UseReward from '../../../assets/images/home/useReward.jfif'
import shrewardop from '../../../assets/images/home/reward.jfif'


import useInView from '../../Common/useInView'
const CashFusePoint = () => {
    const [isVisible] = useState(false);

    const [ref, isInView] = useInView();
    return (
        <>

            <section className='w-full lg:pt-20 lg:pb-10  pt-10 pb-10 cashpointBanner' style={{ background: `url(${PointBg})` }}>
                <div className="container m-auto">

                    <div className='lg:mb-10 p-4 lg:p-0' ref={ref}>
                        <h2
                            className={`font-bold text-custom-blue lg:text-[40px] text-[30px] md:text-[35px] ${isInView ? 'animate-fadeInLeft opacity-100' : 'opacity-0'
                                }`}
                            style={{ animationDelay: '0s' }}
                        >
                            Earn CashFuse Point?
                        </h2>
                        <p
                            className={`text-sm sm:text-base lg:text-lg text-gray-400 pt-1 ${isInView ? 'animate-fadeInLeft opacity-0' : 'opacity-0'
                                }`}
                            style={{ animationDelay: '0.5s' }}
                        >
                            Super Savings at million+ stores around you!
                        </p>
                    </div>
                    <div className="relative grid grid-cols-12  gap-3 xl:gap-6 lg:gap-6 items-center  pt-4" >


                        <div className={`col-span-12 lg:col-span-2 md:col-span-3 sm:col-span-4 ${isInView ? 'animate-fadeInDown opacity-100' : 'opacity-0'}`}>

                            <div className=" text-center grid m-auto items-center justify-center shadow-custom bg-gray-100 rounded-md  p-8  ">
                                <img src={shop} alt="" className=" w-[96px] " />
                                <h6 className='pt-4'>Choose Shop  </h6>
                            </div>


                        </div>
                        <div className={`col-span-12 lg:col-span-2 md:col-span-3 sm:col-span-4 ${isInView ? 'animate-fadeInDown opacity-100' : 'opacity-0'}`}>

                            <div className="text-center m-auto grid items-center justify-center shadow-custom bg-gray-100 rounded-md  p-8  ">
                                <img src={CaseImg} alt="" className='w-[96px]' />
                                <h6 className='pt-4'>Pay Bill </h6>
                            </div>
                        </div>
                        <img
                            src={Arrow1}
                            alt="arrow"
                            className={`absolute xl:left-32 lg:left-12 bottom-[-10px] hidden lg:block
    ${isInView ? 'animate-fadeInUp opacity-0' : 'opacity-0'}
    ${isVisible ? '' : 'hidden'}
  `}
                        />


                        <div className="col-span-12 lg:col-span-4 md:col-span-3 sm:col-span-4">

                            <div className="text-center m-auto items-center max-w-80 justify-center   ">
                                <img src={CashImgMid} alt="" className="w-full " />

                            </div>
                        </div>



                        <div className={`col-span-12 lg:col-span-2 md:col-span-3 sm:col-span-4 ${isInView ? 'animate-fadeInUp opacity-100' : 'opacity-0'}`}>

                            <div className="text-center m-auto grid items-center justify-center shadow-custom bg-gray-100 rounded-md   p-8  ">
                                <img src={shrewardop} alt="" className="w-[96px] " />
                                <h6 className='pt-4'>Get Reward  </h6>
                            </div>
                        </div>

                        <div className={`col-span-12 lg:col-span-2 md:col-span-3 sm:col-span-4 ${isInView ? 'animate-fadeInUp opacity-100' : 'opacity-0'}`}>

                            <div className="text-center m-auto grid items-center justify-center shadow-custom rounded-md bg-gray-100   p-8  ">
                                <img src={UseReward} alt="" className="w-[96px] " />
                                <h6 className='pt-4'>Use Reward </h6>
                            </div>

                        </div>
                        <img src={Arrow2} alt="arrow" className={`absolute animate-fadeInDown opacity-0 xl:right-32 lg:right-11 top-0 hidden lg:block
                        ${isInView ? 'animate-fadeInDown opacity-0' : 'opacity-0'}
                             ${isVisible ? '' : 'hidden'}`} />
                    </div>
                </div>

            </section>




        </>
    )
}

export default CashFusePoint;
