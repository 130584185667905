import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo/LOGO.png"; 

const Footer = () => {
  const footerSections = [
    {
      title: "Company",
      links: [
        { name: "About", path: "/about" },
        { name: "Offer", path: "/offer" },
        { name: "Contact us", path: "/contactus" },
      ],
    },
    {
      title: "Quick Link",
      links: [
        { name: "Share Location", path: "#" },
        { name: "FAQs", path: "/faq" },
      ],
    },
    {
      title: "Legal",
      links: [
        { name: "Terms & conditions", path: "/terms" },
        { name: "Privacy Policy", path: "/privacypolicy" },
      ],
    },
  ];

  return (
    <footer className="bg-[#025D89] py-20">
      <div className="container mx-auto ">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2 gap-8">
          {/* First Column */}
          <div>
            <h4 className="text-white text-2xl font-bold mb-4">
              <img src={Logo} alt="Logo" className="bg-white p-2 rounded-md" />
            </h4>
            <p className="text-gray-300 mb-4">
              Complete your style with awesome clothes from us. Lorem ipsum dolor, sit amet consectetur adipisicing
              elit. Pariatur, quae!
            </p>
          </div>

          {/* Other Columns */}
          {footerSections.map((section, index) => (
            <div key={index}>
              <h4 className="text-gray-200 text-lg font-semibold mb-4">{section.title}</h4>
              <ul>
                {section.links.map((link, idx) => (
                  <li key={idx} className="mb-2">
                    <Link to={link.path} className="text-gray-400 hover:text-white transition">
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
