import React, { useState } from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import BG1 from "../../assets/images/AboutUs/bg.png";
import BG2 from "../../assets/images/AboutUs/bg1.png";
import Left1 from "../../assets/images/Offers/watch.png";
import Left2 from "../../assets/images/Offers/mobile.png";
import StoreCard from '../Common/Cards/StoreCard';

import { useLocation } from 'react-router-dom';


const AllCashfuseStore = () => {
    const location = useLocation();
    const { TopOfferData } = location.state || {};
    console.log(TopOfferData, "AllCashfuseStore");

    const merchants = TopOfferData?.merchant || [];
    const offers = TopOfferData?.offers || [];
    const BASE_URL = "https://liveuat.com:5438";

    const [currentImage, setCurrentImage] = useState(0);
    const images = [
        {
            src: BG1,
            leftImg: Left1,
            dealhadding: "Best Deal Online on smart watches",
            heading: "SMART WEARABLE.",
            description: "Up to 25 Cashfuse Off",
            color: "rgba(0, 142, 204, 1)",

        },
        {
            src: BG2,
            leftImg: Left2,
            dealhadding: "Best Deal on XIAOMI 11i",
            heading: "Mobile Phone",
            description: "Up to 25 Cashfuse Off",
            color: "rgba(231, 57, 33, 1)",
        },

    ];

    const handleNext = () => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentImage((prevImage) =>
            prevImage === 0 ? images.length - 1 : prevImage - 1
        );
    };
    return (
        <>
            <div>

                <div
                    className="relative container m-auto mt-5 mb-5 justify-around bg-no-repeat bg-cover rounded-lg text-white grid grid-cols-1 lg:grid-cols-2 items-center"
                    style={{
                        backgroundImage: `url(${images[currentImage].src})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",

                    }}
                >

                    <div className=" p-8 lg:p-12 lg:ms-10  sm:p-10">
                        <h5 className="font-['Roboto'] text-sm sm:text-base lg:text-lg text-gray-100">{images[currentImage].dealhadding}</h5>
                        <h2 className="font-['Roboto Slab'] flex   font-bold text-white lg:text-[50px] text-[40px] md:text-[45px]">
                            <img src={images[currentImage].icon} alt="" className={`${images[currentImage].icon ? "pe-3" : ""}`} />
                            {images[currentImage].heading}
                        </h2>
                        <p className="font-['Roboto'] text-sm sm:text-base lg:text-lg text-gray-100 ">
                            {images[currentImage].description}
                        </p>
                    </div>
                    <div className="hidden lg:block m-auto">
                        <img src={images[currentImage].leftImg} alt="" className="p-10 w-72  object-cover" />
                    </div>



                    <button
                        className="rounded-full border-8 border-white  flex items-center justify-center absolute left-0 transform -translate-x-1/2 top-1/2 -translate-y-1/2"
                        style={{
                            backgroundColor: "rgba(243, 249, 251, 1)",
                            color: images[currentImage].color,

                        }}
                        onClick={handlePrev}
                    >
                        <MdKeyboardArrowLeft size={24} />
                    </button>
                    <button
                        className="rounded-full border-8 border-white  flex items-center justify-center absolute right-0 transform translate-x-1/2 top-1/2 -translate-y-1/2"
                        style={{
                            backgroundColor: "rgba(243, 249, 251, 1)",
                            color: images[currentImage].color,

                        }}
                        onClick={handleNext}
                    >
                        <MdKeyboardArrowRight size={24} />
                    </button>
                </div>



                <div className="container m-auto py-16">

                    <h3 className="text-[#025D89] font-semibold text-[35px] pb-5">
                        CashFuse Store
                    </h3>
                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 '>

                        {offers.map((offer) => {
                            // Find the associated merchant details for this offer
                            const merchantDetails = merchants.find(
                                (merchant) => merchant._id === offer.merchantId?._id
                            );

                            // Extract details for the card
                            const shopNameStore = merchantDetails?.businessDetils?.shopName || "Shop Name";
                            const Address = merchantDetails?.businessDetils?.businessAddress || "Address not available";
                            const distance = "N/A"; // Add logic for distance if needed
                            const TopStoreOffer = offer.storeOffer || "0%";
                            const storeOffer = merchantDetails?.storeOffer || [];

                            const shopImages = merchantDetails?.businessDetils?.shopImage || [];
                            const shopImage = shopImages.length > 0 ? `${BASE_URL}/${shopImages[0]}` : null;
                            const workingHour = merchantDetails?.businessDetils?.workingHours || "Time Unavailable";
                            const PhNumber = merchantDetails?.businessDetils?.business_mobileNo || "Phone number not available";
                            const Terms = merchantDetails?.terms[0]?.description || "Terms not available";

                            return (
                                <StoreCard
                                    key={offer._id} // Use offer ID as the unique key
                                    shopName={shopNameStore}
                                    storeOffer={TopStoreOffer}
                                    address={Address}
                                    distance={distance}
                                    logo={shopImage}
                                    buttonText="Shop Now"
                                    link="/shopdetail"
                                    state={{
                                        shopName: shopNameStore,
                                        workingHour: workingHour,
                                        PhNumber: PhNumber,
                                        Address: Address,
                                        Terms: Terms,
                                        merchantDetails: merchantDetails,
                                        storeOffer: storeOffer,
                                        shopImages: shopImages,
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>

            </div >


        </>
    )
}

export default AllCashfuseStore;
