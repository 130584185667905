import React, { useState, useEffect } from 'react';
import BackgroundImage from '../../assets/images/img/SIGNUP-BG.png';
import LoginImg from '../../assets/images/img/login.png';
import Button from '../Common/Button';
import { useNavigate, useLocation} from 'react-router-dom';
import useNetworkServices from '../../Networking/baseAPIManager';
import { HeaderLogo } from './HeaderLogo';

const NewPassword = () => {
  const { ResetPassword } = useNetworkServices();
  const navigate = useNavigate();

  const location = useLocation();

  // Extract the otpToken from the location state
  const otpToken = location.state?.otpToken;
  console.log(otpToken, "otpToken");

  // State to manage form inputs and error
  const [formData, setFormData] = useState({ password: '', confirmPassword: '', token: '' });

  console.log(otpToken, "otpToken")
  const [error, setError] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await ResetPassword({
        newPassword: formData.password,
        confirmPassword: formData.confirmPassword,
        token: formData.token
      });
      console.log(response, response.data, "ResponseData");
      navigate("/signin");
    } catch (error) {
      console.error("Reset password error:", error);
      setError('An error occurred. Please try again.');
    }
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      token: otpToken
    }));
  }, [otpToken]);


  return (
    <>
      <div className=' items-center justify-center min-h-screen bg-cover bg-center' style={{ backgroundImage: `url(${BackgroundImage})` }}>
      
          <HeaderLogo />


      
        <div className="flex items-center justify-center  ">
        <div className="container p-8   lg:m-0 md:m-0">
          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
              <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-white dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                    New Password
                  </h1>
                  <p className='text-gray-400 text-sm'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perferendis fugiat veritatis eligendi necessitatibus.</p>
                  {error && <p className="text-red-500">{error}</p>}
                  <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" onSubmit={handleSubmit}>
                    <div>
                      <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Enter New Password</label>
                      <input
                        type="password"
                        id="password"
                        value={formData.password}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="confirmPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Confirm Password</label>
                      <input
                        type="password"
                        id="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                        className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
                        required
                      />
                    </div>
                    <Button type="submit" className={"bg-custom-blue w-full text-white font-bold py-2 px-4 rounded hover:bg-custom-blue2 transition duration-300"} text={"Submit"} />
                  </form>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
              <img src={LoginImg} alt="Login" className="max-w-full h-auto" />
            </div>
          </div>
          </div>
          </div>
      </div>
    </>
  );
};

export default NewPassword;
