import React, { useEffect, useState, useRef } from "react";
import SubHeaderMenu from "./Sidebar/SubHeaderMenu";
import Button from "./Common/Button";
import { useLocation } from "react-router-dom";
import Tabs from "./MyProfileComponents/Tabs";
import MyBusiness from "./MyProfileComponents/MyBusiness";
import BankDetails from "./MyProfileComponents/BankDetails";
import Document from "./MyProfileComponents/Document";
import PersonalInfo from "./MyProfileComponents/PersonalInfo";
import UserDefaultImage from "../assets/images/UserDefultImage.png";
import useNetworkServices from "../Networking/baseAPIManager";
import { CameraIcon } from "@heroicons/react/24/solid";

const MyProfile = ({ onProfileUpdate }) => {
  const [imgData, setImgData] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [shopName, setShopname] = useState(null);
  const [userProfileImage, setUserProfileImage] = useState(UserDefaultImage);
  const ImageInputRef = useRef();

  const [imageFileError, setImageFileError] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [previewSource, setPreviewSource] = useState(null);
  const fileInputRef = useRef(null);

  const location = useLocation();
  const { GetProfile, UpdateProfile } = useNetworkServices();
  const BASE_URL = "https://liveuat.com:5438";

  const fetchProfileData = async () => {
    try {
      const response = await GetProfile();
      setProfileData(response.data.data);
      setShopname(response.data.data.businessDetils);
      setImgData(response.data.data.profileImage);
      onProfileUpdate(response.data.data);

      localStorage.setItem("profileImage", response.data.data.profileImage);
      localStorage.setItem(
        "profileName",
        `${response.data.data.firstName} ${response.data.data.lastName}`
      );
      localStorage.setItem(
        "shopName",
        response.data.data.businessDetils.shopName
      );
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, [GetProfile]);

  const profileUpdateHandler = async (formData) => {
    try {
      const response = await UpdateProfile(formData);
      console.log(response.data, "ResponseData");

      // if (response.data && response.data.profileImage) {
      //   setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     profileImage: response.data.profileImage,
      //   }));

      //   onImageChange(response.data.profileImage);
      //   console.log(response.data.profileImage, "Profile====>");
      // }

      onProfileUpdate();
    } catch (error) {
      console.error("Update Profile error:", error);
    }
  };
  useEffect(() => {
    if (imageFile) {
      const formData = new FormData();
      formData.append("profileImage", imageFile);
      profileUpdateHandler(formData);
    }
  }, [imageFile]);
  const handleImageChange = (file) => {
    setImgData(file);
    setUserProfileImage(file);
    onProfileUpdate({ ...profileData, profileImage: file });
  };

  const handleProfileUpdate = () => {
    fetchProfileData();
  };

  const userImage = imgData
    ? typeof imgData === "object"
      ? URL.createObjectURL(imgData)
      : `${BASE_URL}/${imgData}`
    : userProfileImage;

  const tabs = [
    {
      title: "Personal Info",
      content: (
        <PersonalInfo
          profileData={profileData}
          onImageChange={handleImageChange}
          // profileImage={imgData}
          setImgData={setImgData}
          onProfileUpdate={handleProfileUpdate}
        />
      ),
    },
    {
      title: "My Business",
      content: (
        <MyBusiness
          profileData={profileData}
          onImageChange={handleImageChange}
          // profileImage={imgData}
          setImgData={setImgData}
          onProfileUpdate={handleProfileUpdate}
        />
      ),
    },
    {
      title: "Bank Details",
      content: (
        <BankDetails
          profileData={profileData}
          onImageChange={handleImageChange}
          // profileImage={imgData}
          setImgData={setImgData}
          onProfileUpdate={handleProfileUpdate}
        />
      ),
    },
    {
      title: "Document",
      content: (
        <Document
          profileData={profileData}
          onImageChange={handleImageChange}
          // profileImage={imgData}
          setImgData={setImgData}
          onProfileUpdate={handleProfileUpdate}
        />
      ),
    },
  ];
  console.log(userImage, "userImage");
  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSizeInBytes = 2 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        setImageFileError("File size should be less than 2MB.");
        return;
      }
      if (
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "image/webp"
      ) {
        setImageFile(file);
        previewFile(file);
      } else {
        setImageFileError(
          "File is not valid.File supported PNG,JPG,JPEG,WEBP."
        );
        return;
      }
    }
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div id="main" className="p-4">
      <h1 className="font-bold text-2xl text-slate-900 pb-5">
        <SubHeaderMenu location={location} />
      </h1>
      <div className="flex items-center gap-5">
        <div className="relative">
          <img
            src={
              previewSource
                ? previewSource
                : `${BASE_URL}/${profileData?.profileImage}`
            }
            // src={
            //   previewSource
            //     ? previewSource
            //     : user?.avatar
            //     ? `${BASE_URL}/${imgData}}`
            //     : `https://api.dicebear.com/5.x/initials/svg?seed=${profileData?.firstName}`
            // }
            alt="Profile Image"
            loading="lazy"
            className="w-16 h-16 md:w-40 md:h-40 rounded-full"
          />
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="hidden"
            accept="image/png,image/jpg,image/jpeg"
          />
          <div
            onClick={handleClick}
            className="absolute right-0 bottom-0 md:right-2 md:bottom-3 border border-[#A4B5C2] bg-custom-white w-5 h-5 md:w-7 md:h-7 flex justify-center items-center rounded-full text-slate-500 cursor-pointer"
          >
            <CameraIcon
              aria-hidden="true"
              className="block h-6 w-6 group-data-[open]:hidden"
            />

            {/* <IoMdCamera size={22} /> */}
          </div>
        </div>
        <div>
          <span className="block text-xl font-semibold">
            {profileData?.firstName}{" "}
            {/* {user?.designation && (
                  <span className=" text-xs text-slate-400">
                    ({user?.designation?.designation})
                  </span>
                )} */}
          </span>

          <span className="text-base block font-normal text-[#515557]">
            {shopName?.shopName}
          </span>
        </div>
      </div>
      {/* <div className="p-6 xl:flex justify-between lg:flex md:flex sm:flex">
        <div className="xl:flex lg:flex md:flex sm:flex  items-center">
          <div className="relative w-16 h-16 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
            <img src={userImage} alt="Profile" />
          </div>
          <div className="ps-4">
            <h3 className="text-xl font-bold text-gray-900">
              {profileData?.firstName} {profileData?.lastName}
            </h3>
            <h6 className="text-md font-semibold text-gray-600 pt-1">
              {shopName?.shopName}
            </h6>
            <p className="text-sm text-gray-400 pt-1">{profileData?.city}</p>
          </div>
        </div>

        <div className="pt-5 xl:pt-0 lg:pt-0">
          <input
            type="file"
            ref={ImageInputRef}
            onChange={(e) => handleImageChange(e.target.files[0])}
            style={{ display: "none" }}
          />
          <Button
            type="button"
            className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
            text="Upload Photo"
            onClick={() => {
              ImageInputRef.current.click();
            }}
          />
        </div>
      </div> */}

      <div>
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
};

export default MyProfile;
