import React from 'react'
import Logo from "../../assets/images/logo/LOGO.svg"
import { Link } from "react-router-dom";
export const HeaderLogo = () => {
    return (
        <>

            <div className='container m-auto    py-5 '>
                <Link to="/" className="inline-block">
                    <img src={Logo} alt="Logo" className="w-[160px]" />
                </Link>

            </div>



        </>
    )
}
