import React from "react";

const Button = ({ size, className, text, onClick, type, icon }) => {
  return (
    <>
      <div>
        <button type={type} size={size} className={className} onClick={onClick} icon={icon}>
          {text}
        </button>
      </div>
    </>
  );
};

export default Button;
