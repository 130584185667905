import axios from "axios";


let BASE_URL = process.env.REACT_APP_BASE_URL;
console.log(BASE_URL, "BASE_URL")
const api = axios.create({
    baseURL: BASE_URL,
    timeout: 30000,
    // headers: { 'ngrok-skip-browser-warning': 'true' }

});

api.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem("token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            // config.headers["Content-Type"] = "application/json";
            // config.headers["Content-Type"] = "multipart/form-data";
            if (config.data instanceof FormData) { config.headers["Content-Type"] = "multipart/form-data"; }
            else
                config.headers["Content-Type"] = "application/json";

        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = "/signin";
        } else {
            return Promise.reject(error);
        }
    }
);


export default api;
