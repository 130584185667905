import React from 'react'
import BG from '../../../assets/images/home/bgFation.png'
import leftFashion from '../../../assets/images/home/baby.png'
import Sale from '../../../assets/images/home/sale.png'
import Button from '../../Common/Button'
import { Link } from 'react-router-dom';
const KidsFashion = ({ TopOfferData }) => {
    return (
        <>
            <section className="py-10">
                <div className="container m-auto">


                    <div className="grid grid-cols-12 xl:gap-6 lg:gap-6 items-center pt-4 " style={{
                        backgroundImage: `url(${BG})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}>

                        <div className='col-span-12 md:col-span-6 lg:col-span-6 '>
                            <div>
                                <h2 className="font-bold text-custom-blue pb-3 lg:text-[40px] text-[30px] md:text-[35px]">Baby & Kids Fashion</h2>
                                <img src={Sale} alt="Sale" className='Sale' />
                                <Link to="/allcashfusestore" state={{ TopOfferData }}>
                                    <Button text="Shop Now" className='bg-custom-blue mt-4  text-white font-bold py-2 px-4 rounded hover:bg-custom-blue2 transition duration-300' />
                                </Link>
                            </div>
                        </div>
                        <div className='col-span-12 md:col-span-6 lg:col-span-6 '>
                            <img src={leftFashion} alt="leftFashion" className='leftFashion' />
                        </div>
                    </div>

                </div>
            </section>

        </>
    )
}

export default KidsFashion;
