import React from 'react'
import BannerImg from '../../../assets/images/slider/banner.png'
import BannerLeftImg from '../../../assets/images/slider/baner-left.png'
import { LuSearch, LuMapPin } from 'react-icons/lu';
import '../Style.css'

const Banner = () => {

    return (
        <>


            <section className="bg-cover bg-center pt-5 xxl:pt-5 xl:pt-8 lg:pb-10 xl:pb-0"
                style={{ backgroundImage: `url(${BannerImg})` }}
            >
                <div className="container mx-auto">
                    <div className="grid grid-cols-12  xl:gap-6 lg:gap-6 items-center">
                        <div className="col-span-12 lg:col-span-7  bg-white lg:bg-transparent   text-center lg:text-left">
                            <h1 className="text-custom-blue font-bold leading-[1.20]  animate-fadeInLeft  lg:text-[40px] md:text-[45px] text-[35px] " style={{ animationDelay: '0s' }}>
                                Super Savings at  <span className="hidden sm:inline"> <br /> </span> Million+ Stores Around You!
                            </h1>
                            <p className="text-sm sm:text-base lg:text-lg text-gray-400 pt-3 animate-fadeInLeft  opacity-0" style={{ animationDelay: '0.5s' }}>
                                Super Savings at million+ stores around you!
                            </p>

                            <div className="mt-5">

                                <div className="flex space-x-20 drop   sm:z-2 sm:relative animate-fadeInLeft opacity-0" style={{ animationDelay: '1s' }}>
                                    <div className="relative flex items-center serch m-auto lg:m-0 ">
                                        <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">

                                            <LuMapPin className="text-gray-50" />
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Search Location..."
                                            className="w-100 xl:w-56  sm:w-100 lg:w-96 md:w-96 rounded-md pl-10 pr-3 py-4 text-sm text-gray-50 bg-custom-red focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200"
                                        />
                                        <div className="absolute inset-y-0 right-3 flex items-center pl-3 pointer-events-none">

                                            <LuSearch className="text-gray-50" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 lg:col-span-4 xl:col-span-4 xxl:col-span-5 pt-11 lg:pt-0 text-center">
                            <img
                                src={BannerLeftImg}
                                alt="Banner Left"
                                className="max-w-full h-auto mx-auto animate-fadeInUp"
                            />
                        </div>
                    </div>
                </div>



            </section>



        </>
    )
}

export default Banner;


