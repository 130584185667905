import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar/Sidebar';

const PrivateLayout = ({ onProfileUpdate, profileImage, profileName }) => {
    let token = sessionStorage.getItem("token")
    let isLogin = token;
    let navigate = useNavigate()


console.log(profileImage,'profileImagexzcxzc')

    return (
        <div>
            <Header profileImage={profileImage} profileName={profileName} />
            <Sidebar />
            <div>
                {isLogin ? <Outlet context={{ onProfileUpdate }} /> : navigate("/signin")}
            </div>
        </div>
    );
}

export default PrivateLayout;
