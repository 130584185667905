import React, { useState, useEffect } from "react";
import SubHeaderMenu from "./Sidebar/SubHeaderMenu";
import { useLocation } from "react-router-dom";
import { Table } from "./Common/Table/Table";
import useNetworkServices from "../Networking/baseAPIManager";
import moment from "moment";
export const CashfuseCustomer = () => {
  const location = useLocation();
  const [tableData, setTableData] = useState([]);
  const { CashfuseCustomers } = useNetworkServices();

  const columns = [
    { header: "S. No", accessor: "sNo" },
    { header: "Customer ID", accessor: "shortId" },
    { header: "Customer Name", accessor: "firstName" },
    { header: "Contact No", accessor: "mobile" },
    { header: "Email Id", accessor: "email" },
    { header: "Date", accessor: "dateOfJoining" },
    { header: "Joining", accessor: "joining" },
    { header: "Cash Fuse", accessor: "cashFuse" },
  ];

  useEffect(() => {
    const customerCashfuse = async () => {
      try {
        const response = await CashfuseCustomers();
        const formattedData = response.data.data.map((item, index) => ({
          ...item,
          sNo: index + 1,
          shortId: item._id.slice(-4),
        }));
        setTableData(formattedData);
      } catch (error) {
        console.error(error);
      }
    };
    customerCashfuse();
  }, [CashfuseCustomers]);

  return (
    <div id="main" className="p-4">
      <h1 className="font-bold text-2xl text-slate-900 pb-5">
        <SubHeaderMenu location={location} />
      </h1>
      <Table columns={columns} data={tableData} />
    </div>
  );
};
