

import React from 'react';
import OfferImg1 from '../../../assets/images/home/offer1.png';
import OfferImg2 from '../../../assets/images/home/offer22.png';
import OfferImg3 from '../../../assets/images/home/offer33.png';
import useInView from '../../Common/useInView';
import CashFuseCard from '../../Common/Cards/CahsFuseCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

const EOffer = ({ TopOfferData }) => {
    const [ref, isInView] = useInView();
    const BASE_URL = "https://liveuat.com:5438";
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const merchants = TopOfferData?.merchant || [];
    if (!TopOfferData || !Array.isArray(TopOfferData.offers)) {
        return null; // or display a fallback UI
    }
    const offerImages = [OfferImg1, OfferImg2, OfferImg3];

    return (
        <section className="lg:py-20 py-10" ref={ref}>
            <div className="container m-auto">
                <div className="lg:mb-10 p-4 lg:p-0">
                    <h2
                        className={`font-bold text-custom-blue lg:text-[40px] text-[30px] md:text-[35px] ${isInView ? 'animate-fadeInLeft opacity-100' : 'opacity-1'
                            }`}
                        style={{ animationDelay: '0s' }}
                    >
                        Top Offers CashFuse
                    </h2>
                    <p
                        className={`text-sm sm:text-base lg:text-lg text-gray-400 pt-1 ${isInView ? 'animate-fadeInLeft opacity-1' : 'opacity-1'
                            }`}
                        style={{ animationDelay: '0.5s' }}
                    >
                        Save more with our curated deals and discounts across top Brands & Merchants
                    </p>


                </div>

                <div className="  xl:gap-6 lg:gap-6 items-center pt-4 ">

                    <Slider {...settings}>
                        {TopOfferData.offers.map((offer, index) => {
                            const shopNameStore = offer?.merchantId?.businessDetils?.shopName || "Shop name not available";
                            const merchantDetails = merchants.find(
                                (merchant) => merchant._id === offer.merchantId?._id
                            );
                            const Address = merchantDetails?.businessDetils?.businessAddress || "Address not available";
                            const distance = "N/A"; // Add logic for distance if needed
                            const TopStoreOffer = offer.storeOffer || "0%";
                            const storeOffer = merchantDetails?.storeOffer || [];
                            const shopImages = merchantDetails?.businessDetils?.shopImage || [];
                            const workingHour = merchantDetails?.businessDetils?.workingHours || "Time Unavailable";
                            const offerAboutUS = merchantDetails?.storeOffer[0]?.offerDescription || "Description not available";
                            const TermsOffer = merchantDetails?.storeOffer[0]?.termsAndConditions || "Terms not available";


                            // const termsAndConditions = merchant?.offers?.termsAndConditions;
                            const AboutUsMErchant = merchantDetails?.businessDetils?.aboutUs || "About Us not available";
                            const TermsMerchant = merchantDetails?.terms[0]?.description || "Terms not available";
                            console.log(TermsMerchant, "TermsMerchant22222222222222")
                            return (
                                <div key={offer.merchantId._id} className="p-2">
                                    <CashFuseCard
                                        shopName={shopNameStore} // Use the extracted shopName
                                        offerName={offer.offerTitle || "Offer Name"}
                                        offerAmount={offer.storeOffer || "Offer Amount"}
                                        backgroundImage={offerImages[index % offerImages.length]}
                                        buttonText="Shop Now"
                                        link="/shopdetail"
                                        state={{
                                            shopName: shopNameStore,
                                            workingHour: workingHour,
                                            Address: Address,
                                            storeOffer: TopStoreOffer,
                                            shopImages: shopImages,
                                            offerAboutUS: offerAboutUS,
                                            TermsMerchant: TermsMerchant,
                                            AboutUsMErchant: AboutUsMErchant,
                                            TermsOffer: TermsOffer,
                                            merchantDetails: merchantDetails,
                                            storeOffer: storeOffer,
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </Slider>


                </div>
            </div>
        </section>
    );
};

export default EOffer;


