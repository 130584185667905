import React, { useState } from "react";

import { IoIosSearch } from "react-icons/io";
import { FaChevronRight } from "react-icons/fa";
import BG1 from "../../assets/images/AboutUs/bg1.png";
import LImg from "../../assets/images/img/Faq.png"
const Faq = () => {
  const faqs = [
    {
      id: 1,
      question: "What is a Payment Gateway?",
      answer:
        "A payment gateway is an ecommerce service that processes online payments for online as well as offline businesses. Payment gateways help accept payments by transferring key information from their merchant websites to issuing banks, card associations and online wallet players.",
    },

    {
      id: 3,
      question: "What platforms does Instapay payment gateway support?",
      answer: "Instapay supports various platforms including web, mobile, and point-of-sale systems.",
    },
    {
      id: 4,
      question: "Does Instapay provide international payments support?",
      answer:
        "Yes, Instapay supports international payments, allowing businesses to accept payments from customers worldwide.",
    },
    {
      id: 5,
      question: "Is there any setup fee or annual maintenance fee that I need to pay regularly?",
      answer: "Instapay may have setup and maintenance fees depending on the service package chosen.",
    },
    {
      id: 6,
      question: "Is there any setup fee or annual maintenance fee that I need to pay regularly?",
      answer: "Instapay may have setup and maintenance fees depending on the service package chosen.",
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFAQ, setSelectedFAQ] = useState(faqs[0]);

  const filteredFAQs = faqs.filter((faq) => faq.question.toLowerCase().includes(searchTerm.toLowerCase()));
  return (
    <>

      <div
        className="container m-auto relative mt-5 bg-no-repeat bg-cover rounded-lg text-white grid grid-cols-1 lg:grid-cols-2 items-center"
        style={{
          backgroundImage: `url(${BG1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Left Content Section */}
        <div className="p-6 sm:p-10 lg:p-12 m-auto text-center lg:text-left">
          <h2 className=" text-[28px] lg:text-[42px] sm:text-[32px] md:text-[32px] font-bold flex justify-center lg:justify-start">
            Frequently Asked Questions
          </h2>
          <p className="text-[22px] text-base  mt-4 mb-2">
            Get Rewarded For Doing Things You Love
          </p>
          <hr className='w-24 ' />
        </div>

        {/* Right Image Section */}
        <div className="flex justify-center items-center p-6 sm:p-10 lg:p-12">
          <img src={LImg} alt="Privacy Policy" className=" " />
        </div>
      </div>
      <div className="p-4 container m-auto pt-5" >
        <div className="w-full">
          <div className="lg:flex md:flex justify-between items-center gap-4 mb-4">
            <h2 className=" font-bold text-[1.5rem]">Frequently Asked Questions</h2>
            <div className="relative  "> 
              <input
                type="text"
                placeholder="Search questions..."
                className="p-3 pl-10 border rounded w-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IoIosSearch className="absolute right-7 top-1/2 transform -translate-y-1/2 text-gray-500" size={20} />
            </div>
          </div>
        </div>
        <div className="lg:flex md:flex p-6 bg-gray-20">
          {/* Left side: FAQ List with Search */}

          <div className="  ">
            <ul>
              {filteredFAQs.map((faq) => (
                <li
                  key={faq.id}
                  className={`flex items-center p-2 mb-2 cursor-pointer rounded text-xl ${selectedFAQ.id === faq.id
                      ? "bg-gray-50 text-custom-blue3 text-2xl text-black-600"
                      : "text-gray-700 "
                    }`}
                  onClick={() => setSelectedFAQ(faq)}
                >
                  <span
                    className={`w-4 h-4 mr-4  rounded-full ${selectedFAQ.id === faq.id ? "bg-blue-600" : "bg-custom-red"
                      }`}
                  ></span>
                  <span className="flex-grow">{faq.question}</span>
                  <FaChevronRight className={`ml-2 ${selectedFAQ.id === faq.id ? "text-blue-400" : "text-red-600"}`} />
                </li>
              ))}
            </ul>
          </div>

          {/* Right side: Selected FAQ Answer */}
          <div className="lg:w-2/3 md:w-full p-6 ml-6 rounded bg-gray-50  ">
            <h2 className="text-xl font-bold mb-2 ">{selectedFAQ.question}</h2>
            <p className="text-gray-600 text-lg">{selectedFAQ.answer}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
