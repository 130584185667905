import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SubHeaderMenu from "./Sidebar/SubHeaderMenu";
import TabsSecond from "./Common/TabsSecond";
import PersonalInfoKyc from "./KYC/PersonalInfoKyc";
import MyBusinessKyc from "./KYC/MyBusinessKyc";
import BankDetailsKyc from "./KYC/BankDetailsKyc";
import DocumentKyc from "./KYC/DocumentKyc";
import useNetworkServices from "../Networking/baseAPIManager";

export const KycComponents = () => {
  const location = useLocation();
  const { GetProfile } = useNetworkServices();
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [kycData, setKycData] = useState(null);

  const fetchProfileData = async () => {
    try {
      const response = await GetProfile(); 
      setKycData(response?.data?.data);
 
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleNext = () => {
    setCurrentStep((prev) => {
      const nextStep = Math.min(prev + 1, tabs.length);
      if (!completedSteps.includes(prev)) {
        setCompletedSteps((prevSteps) => [...prevSteps, prev]);
      }
      return nextStep;
    });
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const setProgressBar = (step) => {
    const percent = (step / tabs.length) * 100;
    return percent.toFixed();
  };

  

  const tabs = [
    {
      title: "Personal Info KYC",
      content: (
        <PersonalInfoKyc
          kycData={kycData}  
          setCurrentStep={setCurrentStep}
          handlePrevious={handlePrevious}
          currentStep={currentStep}
          handleNext={handleNext}
          setProgressBar={setProgressBar}
        />
      ),
    },
    {
      title: "My Business KYC",
      content: (
        <MyBusinessKyc
          kycData={kycData} 
          setCurrentStep={setCurrentStep}
          handlePrevious={handlePrevious}
          currentStep={currentStep}
          handleNext={handleNext}
          setProgressBar={setProgressBar}
        />
      ),
    },
    {
      title: "Bank Details KYC",
      content: (
        <BankDetailsKyc
          kycData={kycData} 
          setCurrentStep={setCurrentStep}
          handlePrevious={handlePrevious}
          currentStep={currentStep}
          handleNext={handleNext}
          setProgressBar={setProgressBar}
        />
      ),
    },
    {
      title: "Document KYC",
      content: (
        <DocumentKyc
          kycData={kycData} 
          handlePrevious={handlePrevious}
          setProgressBar={setProgressBar}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          handleNext={handleNext}
        />
      ),
    },
  ];

  return (
    <div id="main" className="p-4">
      <h1 className="font-bold text-2xl text-slate-900 pb-5">
        <SubHeaderMenu location={location} />
      </h1>
      <div>
        <TabsSecond
          tabs={tabs}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          setProgressBar={setProgressBar}
          completedSteps={completedSteps}
        />
      </div>
    </div>
  );
};
