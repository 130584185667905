import React, { useState } from "react";
import BackgroundImage from "../../assets/images/img/SIGNUP-BG.png";
import LoginImg from "../../assets/images/img/login.png";
import Button from "../Common/Button";
import { NavLink, useNavigate } from "react-router-dom";
import useNetworkServices from "../../Networking/baseAPIManager";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Location from "./Location";
import { HeaderLogo } from "./HeaderLogo";
import { showToast } from "../Common/Toastify";

const SignUp = () => {
  const { signUpMerchant } = useNetworkServices();
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    mobile: "",
    password: "",
    type: "merchant",
    location: {
      type: "Point",
      coordinates: [],
    },
    field_executive: "",
  });

  const handleLocationChange = (location) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: {
        type: "Point",
        coordinates: [location.longitude, location.latitude],
      },
    }));
  };

  const [errors, setErrors] = useState({});
  const [mobileNumber, setMobileNumber] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const validateMobileNumber = (number) => {
    const errors = {};
    if (!number.trim()) {
      errors.mobile = "Phone Number is required";
    } else if (!/^\d{10}$/.test(number)) {
      errors.mobile = "Phone Number must be exactly 10 digits";
    } else if (/^(.)\1{9}$/.test(number)) {
      errors.mobile = "Phone Number is invalid";
    }
    return errors;
  };
  const validate = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) {
      newErrors.firstName = "User Name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
    }
    return newErrors;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      const onlyDigits = value.replace(/\D/g, "");
      setMobileNumber(onlyDigits);

      const validationErrors = validateMobileNumber(onlyDigits);
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: validationErrors.mobile,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    const mobileValidationErrors = validateMobileNumber(mobileNumber);
    const combinedErrors = { ...validationErrors, ...mobileValidationErrors };
    setErrors(combinedErrors);

    if (Object.keys(combinedErrors).length === 0) {
      try {
        const response = await signUpMerchant({ ...formData, mobile: mobileNumber });
        console.log(response, "ResponseData");
        navigate("/signin");
      } catch (error) {
        console.error("Sign up error:", error.response.data.message.slice(0, 22));
        if (error.response.data.message.slice(0, 22) == "Can't extract geo keys") {
          showToast("Your Geo loaction is not onn please on your location", "error");
        } else {
          showToast("already exists", "error");
          
        }
      }
    }
  };

  console.log(formData, "formData");

  return (
    <div
      className=" bg-cover bg-center min-h-screen"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >

      <HeaderLogo />

      <Location onLocationChange={handleLocationChange} />

      <div className="flex items-center justify-center  ">
      <div className="container p-8   lg:m-0 md:m-0 ">
        <div className="grid grid-cols-12 gap-8  ">
          <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
            <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-white dark:border-gray-700">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                  Sign Up
                </h1>
                <p className="text-gray-400 text-sm">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perferendis fugiat veritatis eligendi
                  necessitatibus.
                </p>
                <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                    >
                      User Name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                      placeholder="First Name"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>}
                  </div>
                  <div>
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                  </div>
                  <div>
                    <label htmlFor="mobile" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="mobile"
                      id="mobile"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                      placeholder="Mobile"
                      value={mobileNumber}
                      onChange={handleChange}
                    />
                    {errors.mobile && <p className="text-red-500 text-xs mt-1">{errors.mobile}</p>}
                  </div>
                  <div className="relative">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                    >
                      Password
                    </label>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      name="password"
                      id="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                      placeholder="Password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 top-6 flex items-center pr-3"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {passwordVisible ? <FaEyeSlash className="text-gray-500" /> : <FaEye className="text-gray-500" />}
                    </button>
                    {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
                  </div>

                  <div>
                    <label
                      htmlFor="Referral By"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                    >
                      Referral By
                    </label>
                    <input
                      type="text"
                      name="Referral By"
                      id="field_executive"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                      placeholder="Referral By"
                      value={formData.field_executive}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <Button
                      type="submit"
                      className={
                        "bg-custom-blue w-full text-white font-bold py-2 px-4 rounded hover:bg-custom-blue2 transition duration-300"
                      }
                      text={"Submit"}
                    />
                  </div>
                  <p className="text-sm font-light">
                    <span className=" text-gray-500 dark:text-gray-600"> Already have an account?</span>
                    <NavLink
                      to="/signin"
                      className="font-medium text-primary-600 text-blue-400 ps-2 hover:underline dark:text-blue-500"
                    >
                      Login here
                    </NavLink>
                  </p>
                </form>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
            <img src={LoginImg} alt="Login" className="max-w-full h-auto" />
          </div>
        </div>
        </div>
        </div>
    </div>
  );
};

export default SignUp;
