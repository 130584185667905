import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Import Link and useLocation from react-router-dom
import Logo from "../../assets/images/logo/LOGO.svg";
import Button from "../Common/Button";
import useNetworkServices from "../../Networking/baseAPIManager";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [open, setOpen] = useState(false); // Dropdown toggle for Sign In/Sign Up
  const [menuOpen, setMenuOpen] = useState(false); // Mobile menu toggle
  const [isMerchantLoggedIn, setIsMerchantLoggedIn] = useState(false);
  const location = useLocation(); // Hook to get the current location
  const BASE_URL = "https://liveuat.com:5438";
  const { GetProfile } = useNetworkServices();
  const [profileImg, setProfileImg] = useState(""); // Store profile image

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    setIsMerchantLoggedIn(!!token); // Check if token exists for login status
    if (token) {
      fetchProfileData(); // Fetch profile data if logged in
    } else {
      setOpen(false); // Hide dropdown when logged out
    }
  }, []);

  const fetchProfileData = async () => {
    try {
      const response = await GetProfile();
      const profileImage = response?.data?.data?.profileImage;
      if (profileImage) {
        setProfileImg(profileImage); // Set profile image if available
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    setIsMerchantLoggedIn(false);
    setProfileImg("");
    setOpen(false); // Close the dropdown after logout
  };

  const navItems = [
    { name: "Home", href: "/" },
    { name: "About", href: "/about" },
    { name: "Offer", href: "/offer" },
    { name: "Contact Us", href: "/contactus" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`bg-white shadow-md ${isSticky ? "sticky top-0 z-50" : ""}`}>
      <nav className="bg-white w-full z-20 top-0 start-0 border-b border-gray-200">
        <div className="container mx-auto py-1 flex justify-between items-center">
          <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={Logo} alt="logo" className="lg:w-[170px] w-[125px]  logo" />
          </Link>

          <div className="flex items-center space-x-3 md:space-x-6 relative">
            <div className="hidden md:flex space-x-6">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`block py-2 px-4 rounded ${location.pathname === item.href
                    ? "text-custom-red font-bold"
                    : "text-gray-800 hover:text-custom-red"
                    }`}
                  aria-current={location.pathname === item.href ? "page" : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>

            {/* Profile and Logout dropdown for logged-in users */}
            {isMerchantLoggedIn && profileImg ? (
              <div className="relative">
                <button
                  onClick={() => setOpen(!open)}
                  className="w-10 h-10 rounded-full overflow-hidden border-2 border-gray-200 focus:outline-none"
                >
                  <img
                    src={`${BASE_URL}/${profileImg}`}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                </button>
                {open && (
                  <div className="absolute right-0 mt-2 bg-white border border-gray-200 rounded-md shadow-lg w-40 z-10">
                    <Link
                      to="/MyProfile"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                    >
                      Profile
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              // Sign In / Sign Up dropdown for logged-out users
              <div className="relative">
                <Button
                  type="button"
                  onClick={() => setOpen(!open)}
                  className="bg-custom-red text-white font-bold button_signin py-2 lg:px-4 px-2  rounded hover:bg-red-600 transition duration-300"
                  text="Sign In / Up"
                />
                {open && !isMerchantLoggedIn && (
                  <div className="absolute right-0 mt-2 bg-white border border-gray-200 rounded-md shadow-lg w-40 z-10">
                    <Link
                      to="/signin"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                    >
                      Sign In
                    </Link>
                    <Link
                      to="/signup"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                    >
                      Sign Up
                    </Link>
                  </div>
                )}

              </div>

            )}

            {/* Sign In / Sign Up dropdown for non-logged-in users */}

            {/* Mobile menu button */}
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              type="button"
              className="md:hidden p-2 w-10 h-10 flex items-center justify-center text-gray-600 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-sticky"
              aria-expanded={menuOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        <div
          className={`md:hidden ${menuOpen ? "block" : "hidden"} bg-white border-t border-gray-200`}
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 space-y-2">
            {navItems.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.href}
                  className={`block py-2 px-3 rounded ${location.pathname === item.href ? "text-custom-red font-bold" : "text-gray-900 hover:bg-gray-100"
                    }`}
                  aria-current={location.pathname === item.href ? "page" : undefined}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
