import React, { useEffect, useState } from "react";
import SubHeaderMenu from "./Sidebar/SubHeaderMenu";
import { useLocation } from "react-router-dom";
import { Table } from "./Common/Table/Table";
import useNetworkServices from "../Networking/baseAPIManager";
import moment from "moment";

export const MyTransection = () => {
  const location = useLocation();
  const [tableData, setTableData] = useState([]);
  const { MyTransection } = useNetworkServices();

  const columns = [
    { header: "S. No", accessor: "sNo" },
    { header: "Transaction ID", accessor: "shortId" },
    { header: "Customer Name", accessor: "fullName" },
    { header: "Contact No", accessor: "mobile" },
    { header: "Date", accessor: "date" },
    { header: "Time", accessor: "formattedTime" },
    { header: "Amount", accessor: "reward_points" },
    // { header: "Payment Mode", accessor: "" },
    // { header: "Status", accessor: "Status" },
    // { header: "Refrral", accessor: "" },
    { header: "CashFuse Offer", accessor: "" },
    { header: "Cash Back", accessor: "" },
  ];

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await MyTransection();

        const formattedData = response.data.data.map((item, index) => ({
          ...item,
          sNo: index + 1,
          shortId: item._id.slice(-4),
          date: moment(item.date).format("YYYY-MM-DD"),
          formattedTime: moment(item.date).format("hh:mm A"),
        }));

        setTableData(formattedData);
        console.log("Formatted Data:", formattedData);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions();
  }, [MyTransection]);

  return (
    <div id="main" className="p-4">
      <h1 className="font-bold text-2xl text-slate-900 pb-5">
        <SubHeaderMenu location={location} />
      </h1>
      <Table columns={columns} data={tableData} />
    </div>
  );
};
