import React, { useState, useEffect } from "react";
import BG1 from "../../../assets/images/AboutUs/bg.png";
import BG2 from "../../../assets/images/AboutUs/bg1.png";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import CategoryCard from "../../Common/Cards/CategoryCard";
import useNetworkServices from "../../../Networking/baseAPIManager";
import { useLocation  } from "react-router-dom";
import StoreCard from "../../Common/Cards/StoreCard";
import { FaStar } from "react-icons/fa";

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const OfferPage = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const location = useLocation();
  const { CategorylistUser, GetMerchantsStore, GetMerchantBanner  } =
    useNetworkServices();
  const [categories, setCategories] = useState([]);
  const [merchants, setMerchants] = useState([]);
  console.log(categories, "categories");
  const BASE_URL = "https://liveuat.com:5438";

  // console.log(location.state.activeCategory, "locationData");
  const { locationData } = location.state || {};



  const [coords, setCoords] = useState({
    lat: locationData?.lat || null,
    long: locationData?.long || null,
  });
  const [activeCategory, setActiveCategory] = useState(null);

  const [BannerData, setBannerData] = useState(null);

  const MerchantBanner = async () => {
    try {
      const response = await GetMerchantBanner();
      console.log(response, 'response response_______')
      if (response) {
        setBannerData(response?.data?.data);
      }
    } catch (error) {
      console.log(error, "error__________");
    }
  };

  useEffect(() => {
    MerchantBanner();
  }, []);

  useEffect(() => {
    if (!coords.lat || !coords.long) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoords({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting location:", error);
          // Provide fallback coordinates if needed
          setCoords({ lat: "defaultLat", long: "defaultLong" });
        }
      );
    }
  }, []);

  useEffect(() => {
    if (location.state?.activeCategory) {
      setActiveCategory(location.state.activeCategory);
    }
  }, [location]);





  useEffect(() => {
    if (coords.lat && coords.long) {
      fetchMerchants(activeCategory);
    }
  }, [activeCategory, coords]);

  const fetchMerchants = async (categoryId) => {
    const { lat, long } = locationData || {
      lat: "defaultLat",
      long: "defaultLong",
    };
    const params = {
      lat: coords.lat,
      long: coords.long,
      page: 1,
      pageSize: 5,
      // categoryId,
    };
    if (categoryId) {
      params.categoryId = categoryId;
    }

    try {
      const response = await GetMerchantsStore({ params });
      setMerchants(response.data.data);
      console.log("Merchant data response:", response);
    } catch (error) {
      console.error("Error fetching merchants:", error);
    }
  };



  useEffect(() => {
    const fatchCategory = async () => {
      try {
        const response = await CategorylistUser();
        if (response?.data?.data) {
          const categoryData = response.data.data;
          setCategories(categoryData);

          
        }
      } catch (error) {
        console.error("category error", error);
      }
    };

    fatchCategory();
  }, []);


  const handleCategorySelect = (categoryId) => {
    setActiveCategory(categoryId);
  };


  const handleNext = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % BannerData?.length);
  };

  const handlePrev = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0 ? BannerData?.length - 1 : prevImage - 1
    );
  };
  const bgImage = [BG1, BG2];


  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Default number of slides to show
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 6, // Show 6 slides for larger screens
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5, // Adjust to 5 for medium-large screens
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4, // 4 slides for standard desktops
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4, // Same as 1200px to keep uniformity
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3, // Compact view for tablets
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2, // Small view for mobile devices
        },
      },
      {
        breakpoint: 480, // Combines smaller screen ranges
        settings: {
          slidesToShow: 2, // Better fit for small mobile devices
        },
      },
      {
        breakpoint: 376,
        settings: {
          slidesToShow: 1, // Single slide for very small devices
        },
      },
    ],
  };


 
  return (
    <div className="container mx-auto px-4 relative py-20">
      {/* Background Image Section */}
      <div
        className="relative justify-around bg-no-repeat bg-cover rounded-lg text-white   items-center"
        style={{
          backgroundImage: `url(${bgImage?.[currentImage % 2]})`,
          backgroundSize: "cover",
          backgroundPosition: "center",

        }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center ">
          <div className=" p-8 lg:p-12 lg:ms-10  sm:p-10">
            <h5 className="font-['Roboto'] text-sm sm:text-base lg:text-lg text-gray-100">{BannerData?.[currentImage]?.tagLine}</h5>
            <h2 className="font-['Roboto Slab'] flex   font-bold text-white lg:text-[50px] text-[40px] md:text-[45px]">
              {/* <img src={images[currentImage].icon} alt="" className={`${images[currentImage].icon ? "pe-3" : ""}`} /> */}
              {BannerData?.[currentImage]?.shopName}
            </h2>
            <p className="font-['Roboto'] text-sm sm:text-base lg:text-lg text-gray-100 ">
              {`Up to ${BannerData?.[currentImage]?.cashBackReward} Cashfuse Off `}
            </p>
          </div>
          <div className="m-auto py-10">
            <img src={`${BASE_URL}/${BannerData?.[currentImage]?.image}`} alt="" className="h-60 w-80  object-cover" />
          </div>
        </div>




        <button
          className="rounded-full border-8 border-white  flex items-center justify-center absolute left-0 transform -translate-x-1/2 top-1/2 -translate-y-1/2"
          style={{
            backgroundColor: "rgba(243, 249, 251, 1)",
            color: currentImage % 2 ? "rgba(231, 57, 33, 1)" : "rgba(0, 142, 204, 1)",

          }}
          onClick={handlePrev}
        >
          <MdKeyboardArrowLeft size={24} />
        </button>
        <button
          className="rounded-full border-8 border-white  flex items-center justify-center absolute right-0 transform translate-x-1/2 top-1/2 -translate-y-1/2"
          style={{
            backgroundColor: "rgba(243, 249, 251, 1)",
            color: currentImage % 2 ? "rgba(231, 57, 33, 1)" : "rgba(0, 142, 204, 1)",

          }}
          onClick={handleNext}
        >
          <MdKeyboardArrowRight size={24} />
        </button>
      </div>

      {/* About Us Section */}
      <section className="font-[roboto] mt-16 ">
        <div className="flex justify-between ">
          <span className="text-[#025D89] font-semibold text-[35px]">
            Popular Casfuse Offer
          </span>
        </div>

        <div className="pt-10">
          {/* <CategoryCard
            categoriesData={filteredCategories}
            activeCategory={activeCategory}
            onCategoryClick={handleCategoryClick}
            BASE_URL={BASE_URL}
          /> */}
          <Slider {...sliderSettings}>
            {categories.map((category) => (
              <CategoryCard
                key={category._id}
                category={category}
                // categoriesData={categories}
                activeCategory={activeCategory}
                onCategoryClick={handleCategorySelect}
                BASE_URL={BASE_URL}
              />
            ))}
          </Slider>
        </div>
      </section>

      {/* Partners Section */}
      <section className="  py-12 ">
        <h3 className="text-[#025D89] font-semibold text-[35px] pb-12">
          Exclusive: Save at Your Fave Outlets
        </h3>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 ">
          {merchants.map((merchant, index) => {
            const shopname = merchant?.businessDetils?.shopName;
            const Address = merchant?.businessDetils?.businessAddress;
            const distance = merchant?.businessDetils?.locationData;
            const Rating = merchant?.totalRatings;
            const workingHour = merchant?.businessDetils?.workingHours;
            const TopStoreOffer = merchant?.businessDetils?.TopStoreOffer;
            const shopImages = merchant?.businessDetils?.shopImage || [];
            const shopImage =
              shopImages.length > 0 ? `${BASE_URL}/${shopImages[0]}` : null;
            const AboutUsOffer = merchant?.offers?.offerDescription || "about us not available";
            const termsOffer = merchant?.offers?.termsAndConditions || "terms not available";
            const aboutUsMerchant = merchant?.businessDetils?.aboutUs || "about us not available";
            const termsMerchant = merchant?.terms || "terms not available";
            console.log(termsMerchant, "termsMerchant")
            

            return (
              <StoreCard
                key={index}
                merchant={merchant}
                shopName={shopname}
                storeOffer={TopStoreOffer}
                address={Address}
                star={<FaStar />}
                Ratings={Rating}
                distance={distance}
                logo={shopImage}
                buttonText="Shop Now"
                link="/shopdetail" 
                state={{
                  shopName: shopname,
                  workingHour: workingHour,
                  Address: Address,
                  storeOffer: TopStoreOffer,
                  shopImages: shopImages,
                  Rating: Rating,
                  termsMerchant: termsMerchant,
                  aboutUsMerchant: aboutUsMerchant,
                  termsOffer: termsOffer,
                  AboutUsOffer: AboutUsOffer
                }}

              />
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default OfferPage;
