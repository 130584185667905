

import React, { useState,useEffect } from 'react';
import { State, City } from 'country-state-city'; // Ensure `country-state-city` is installed
import Button from '../Common/Button';
import useNetworkServices from '../../Networking/baseAPIManager';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Dropdown } from 'primereact/dropdown';

const PersonalInfoKyc = ({ kycData, handleNext, currentStep }) => {
    const { UpdateProfile } = useNetworkServices();
    const navigate = useNavigate();
    const [states] = useState(State.getStatesOfCountry('IN')); // Fetch all states of India
    const [cities, setCities] = useState([]);

    console.log(kycData,"formdataaaaaaaaa")

    const [initialValues, setInitialValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        address: '',
        pinCode: '',
        state: '',
        city: '',
    });

console.log(initialValues,"initalValues")
    useEffect(() => {
        if (kycData) {
            setInitialValues({
                firstName: kycData?.firstName || '',
                lastName: kycData?.lastName || '',
                email: kycData?.email || '',
                mobile: kycData?.mobile || '',
                address: kycData?.address || '',
                pinCode: kycData?.pinCode || '',
                state: kycData?.state || '',
                city: kycData?.city || '',
            });

           // Set cities if the state is already selected
           
        }
    }, [kycData, states]);
     

    useEffect(() => {
        if (kycData?.state) {
            const selectedState = states.find((state) => state.name === kycData.state);
            if (selectedState) {
                const fetchedCities = City.getCitiesOfState('IN', selectedState.isoCode);
                setCities(fetchedCities);
            }
        }
    }, [kycData, states]);



   

    // Validation schema
    const validationSchema = Yup.object({
        firstName: Yup.string()
            .matches(/^[A-Z][a-zA-Z]*$/, 'First name must start with a capital letter and contain no spaces')
            .required('First Name is Required'),
        lastName: Yup.string()
            .matches(/^[A-Z][a-zA-Z]*$/, 'Last name must start with a capital letter and contain no spaces')
            .required('Last Name is Required'),
        email: Yup.string()
            .email('Invalid Email Address')
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?<!\.\w{2,}\.\w{2,})$/,
                'Email must not have a double domain'
            )
            .required('Email is Required'),
        mobile: Yup.string()
            .matches(/^[6-9]\d{9}$/, 'Invalid and must be exactly 10 digits')
            .notOneOf([/^(.)\1{9}$/], 'Phone number is invalid')
            .required('Phone Number is required'),
        address: Yup.string().required('Address is required'),
        pinCode: Yup.string()
            .matches(/^\d{6}$/, 'Pincode must be a valid 6-digit number')
            .required('Pincode is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
    });

    const formik = useFormik({
        enableReinitialize: true, // Allow dynamic reinitialization when initialValues change
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const data = new FormData();
            Object.keys(values).forEach((key) => {
                data.append(key, values[key]);
            });
            try {
                const response = await UpdateProfile(data);
                console.log(response.data, 'ResponseData');
                handleNext();
                const nextStep = currentStep + 1;
                localStorage.setItem('currentStep', nextStep);
                navigate('/kycComponents#mybussinesskyc');
            } catch (error) {
                console.error('Update Profile error:', error);
            }
        },
    });

    const handleStateChange = (e) => {
        const selectedStateCode = e.target.value;
        const selectedState = states.find((state) => state.isoCode === selectedStateCode);

        if (selectedState) {
            // Update Formik's state field
            formik.setFieldValue('state', selectedState.name);
            formik.setFieldValue('state', e.target.value);
            // Fetch cities for the selected state
            const updatedCities = City.getCitiesOfState('IN', selectedStateCode);
            setCities(updatedCities);
           

            // Reset city value
            formik.setFieldValue('city', '');
        }
    };

    const handleCityChange = (e) => { 
        console.log(e)
        formik.setFieldValue('city', e.target.value); // Update city field in Formik
 
    };

    const handleCancel = () => {
        formik.resetForm();
    };

    return (
        <div className="pt-8">
            <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
                    {[
                        { name: 'firstName', type: 'text', label: 'First Name', placeholder: 'First name' },
                        { name: 'lastName', type: 'text', label: 'Last Name', placeholder: 'Last name' },
                        { name: 'email', type: 'email', label: 'Email', placeholder: 'Email' },
                        { name: 'mobile', type: 'text', label: 'Phone Number', placeholder: 'Phone number' },
                        { name: 'address', type: 'text', label: 'Address', placeholder: 'Address' },
                        { name: 'pinCode', type: 'text', label: 'Pincode', placeholder: 'Pincode' }, 
                       
                    ].map((field) => (
                        <div key={field.name}>
                            <label
                                htmlFor={field.name}
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                            >
                                {field.label}
                            </label>
                            <input
                                type={field.type}
                                name={field.name}
                                id={field.name}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                                placeholder={field.placeholder}
                                value={formik.values[field.name]}
                                onChange={(e) => {
                                    if (field.name === 'mobile' || field.name === 'pinCode') {
                                        // Allow only numeric input
                                        const value = e.target.value.replace(/[^0-9]/g, '');
                                        formik.setFieldValue(field.name, value);
                                    } else {
                                        formik.handleChange(e);
                                    }
                                }}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched[field.name] && formik.errors[field.name] && (
                                <div className="text-red-500 text-sm">{formik.errors[field.name]}</div>
                            )}

                        </div>
                    ))}
                    <div>
                        <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
                            State
                        </label>
                        <select
                            id="state"
                            name="state"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                            value={formik.values.state}
                            onChange={handleStateChange}
                            onBlur={formik.handleBlur}
                        >
                            <option value="">Select State</option>
                            {states.map((state) => (
                                <option key={state.isoCode} value={state.isoCode}>
                                    {state.name}
                                </option>
                            ))}
                        </select>
                        {formik.touched.state && formik.errors.state && (
                            <div className="text-red-500 text-sm">{formik.errors.state}</div>
                        )}
                    </div>

                    {/* City Dropdown */}
                    <div>
                        <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
                            City
                        </label>
                        <select
                            id="city"
                            name="city"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                            value={formik.values.city}
                            onChange={handleCityChange}
                            onBlur={formik.handleBlur} // Disable if no cities are available
                            defaultValue={initialValues.city}
                        >
                            <option value="">{initialValues.city}</option>
                            {cities.map((city ,i) => (
                                <option key={i} value={city.name}>
                                    {city.name}
                                </option>
                            ))}
                        </select>
                        {formik.touched.city && formik.errors.city && (
                            <div className="text-red-500 text-sm">{formik.errors.city}</div>
                        )}
                    </div>
                </div>


                {/* Buttons */}
                <div div className="flex justify-end gap-4 pt-5" >
                    <Button
                        type="button"
                        className="border-custom-red border hover:border-custom-red w-full xl:mt-0 lg:mt-0 hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
                        text="Cancel"
                        onClick={handleCancel}
                    />
                    <Button
                        type="submit"
                        className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
                        text="Next"
                    />
                </div>
            </form>
        </div>
    );
};

export default PersonalInfoKyc;
