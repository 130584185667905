import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";

const AllReviews = () => {
    // Mock data for reviews
    const [reviews, setReviews] = useState([]);

    // Fetch reviews from an API (mocked for now)
    useEffect(() => {
        const fetchReviews = async () => {
            const mockData = [
                {
                    id: 1,
                    username: "Ca.parimal.agarwal",
                    userReviews: 0,
                    followers: 0,
                    rating: 4,
                    dining: true,
                    date: "8 days ago",
                    content: "The food was good",
                    votesHelpful: 0,
                    comments: 0,
                },
                {
                    id: 2,
                    username: "John.Doe",
                    userReviews: 5,
                    followers: 10,
                    rating: 5,
                    dining: false,
                    date: "3 days ago",
                    content: "Amazing experience!",
                    votesHelpful: 3,
                    comments: 2,
                },
                // Add more reviews to test scrolling
                {
                    id: 3,
                    username: "Jane.Smith",
                    userReviews: 3,
                    followers: 7,
                    rating: 3,
                    dining: true,
                    date: "1 day ago",
                    content: "It was okay.",
                    votesHelpful: 1,
                    comments: 1,
                },
                // More mock data here...
            ];
            setReviews(mockData);
        };
        fetchReviews();
    }, []);

    return (
        <div className="bg-white p-6 rounded-lg shadow-md mx-auto ">
            <h2 className="text-xl mb-4">Cashfuse Review</h2>
            {/* Dropdown for filter (future feature) */}
            <div className="flex justify-between items-center mb-4">
                <p className="text-gray-600">All Reviews</p>
                <select className="border border-gray-300 rounded px-3 py-1">
                    <option>All Reviews</option>
                    <option>5-Star Reviews</option>
                    <option>4-Star Reviews</option>
                    <option>Recent Reviews</option>
                </select>
            </div>

            {/* Scrollable Reviews List */}
            <div className="max-h-64 overflow-y-auto">
                {reviews.length > 0 ? (
                    reviews.map((review) => (
                        <div
                            key={review.id}
                            className="border-b border-gray-200 pb-4 mb-4 last:border-none last:pb-0 last:mb-0"
                        >
                            {/* Reviewer Info */}
                            <div className="flex items-center mb-2">
                                <div className="w-8 h-8 bg-gray-300 rounded-full flex items-center justify-center text-sm text-gray-500">
                                    {review.username.charAt(0).toUpperCase()}
                                </div>
                                <div className="ml-3">
                                    <p className="font-medium text-gray-700">{review.username}</p>
                                    <p className="text-sm text-gray-500">
                                        {review.userReviews} reviews • {review.followers} Followers
                                    </p>
                                </div>
                            </div>

                            {/* Rating and Content */}
                            <div className="flex items-center">
                                <span className="flex items-center bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                                    {review.rating}
                                    <FaStar className="ml-1 text-xs" />
                                </span>
                                {review.dining && (
                                    <span className="ml-2 text-xs font-semibold text-gray-500">
                                        DINING
                                    </span>
                                )}
                                <span className="ml-4 text-xs text-gray-500">{review.date}</span>
                            </div>
                            <p className="mt-2 text-gray-700">{review.content}</p>

                            {/* Votes and Comments */}
                            <p className="mt-1 text-xs text-gray-500">
                                {review.votesHelpful} Votes for helpful, {review.comments}{" "}
                                {review.comments === 1 ? "Comment" : "Comments"}
                            </p>
                        </div>
                    ))
                ) : (
                    <p className="text-gray-600">No reviews available</p>
                )}
            </div>
        </div>
    );
};

export default AllReviews;
