import React, { useEffect, useState } from "react";
import Button from "../Common/Button";
import useNetworkServices from "../../Networking/baseAPIManager";
import { useNavigate } from "react-router-dom";
import { FaFileUpload } from "react-icons/fa";

const Document = ({
  onImageChange,
  profileImage,
  profileData,
  onProfileUpdate,
}) => {
  const { UpdateProfile } = useNetworkServices();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState({ aadhar: "", panCard: "" });
  const [imagePreview, setImagePreview] = useState(profileImage || "");

  const [formData, setFormData] = useState({
    aadhar: "",
    panCard: "",
    // image: profileImage,
  });

  useEffect(() => {
    if (profileData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        aadhar: profileData?.aadhar || "",
        panCard: profileData?.panCard || "",
        // image: profileImage,
      }));

      setFileName({
        aadhar: profileData?.aadhar ? profileData.aadhar.split("\\").pop() : "",
        panCard: profileData?.panCard
          ? profileData.panCard.split("\\").pop()
          : "",
      });
    }
  }, [
    profileData,
    // , profileImage
  ]);

  const [error, setErrors] = useState({});

  const validateForm = () => {
    const newError = {};
    if (!formData.aadhar) {
      newError.aadhar = "Aadhar Card  is required";
    }

    // Pan Card validation
    if (!formData.panCard) {
      newError.panCard = "Pan Card  is required";
    }
    setErrors(newError);
    return Object.keys(newError).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = new FormData();
      Object.keys(formData).forEach((key) => {
        data.append(key, formData[key]);
      });

      try {
        const response = await UpdateProfile(data);
        if (response.data && response.data.photo) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            image: response.data.photo,
          }));
          onImageChange(response.data.photo);
        }
        console.log(response.data, "ResponseData");
        onProfileUpdate();
        navigate("/dashboard");
      } catch (error) {
        console.error("Update Profile error:", error);
      }
    }
  };

  const handleCancel = () => {
    setFormData({
      aadhar: "",
      panCard: "",
      image: "",
    });
    navigate("/MyProfile");
  };

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];

    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: file, // Update the selected file
    }));

    setFileName((prevNames) => ({
      ...prevNames,
      [fieldName]: file ? file.name : "", // Set the file name
    }));

    if (fieldName === "image" && file) {
      setImagePreview(URL.createObjectURL(file)); // Update the image preview
    }

    const newError = { ...error };
    if (file) {
      delete newError[fieldName]; // Remove the error for the selected file
    }
    setErrors(newError);
  };

  const inputFields = [
    {
      name: "aadhar",
      type: "text",
      label: "Aadhar Card",
      placeholder: "Aadhar Card",
    },
    {
      name: "panCard",
      type: "text",
      label: "Pan Card",
      placeholder: "Pan Card",
    },
  ];

  return (
    <div className="pt-8">
      <form className="space-y-4 md:space-y-6" onSubmit={handleFormSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
          {inputFields.map((field) => (
            <div key={field.name}>
              <label
                htmlFor={field.name}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
              >
                {field.label}
              </label>
              <div className="relative w-full">
                <input
                  type="file"
                  name={field.name}
                  id={field.name}
                  className="hidden"
                  onChange={(e) => handleFileChange(e, field.name)}
                />
                <label
                  htmlFor={field.name}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 block w-full cursor-pointer flex items-center justify-between"
                >
                  <span className="flex items-center">
                    {/* File icon */}
                    {fileName[field.name]
                      ? fileName[field.name]
                      : "Choose file"}
                  </span>
                  <FaFileUpload className="mr-2" /> {/* Custom browse button */}
                </label>
                {field.name === "image" && imagePreview && (
                  <img src={imagePreview} alt="Profile Preview" />
                )}
              </div>

              {error[field.name] && (
                <div className="text-red-500 text-sm">{error[field.name]}</div>
              )}
            </div>
          ))}
        </div>

        <div className="xl:flex lg:flex flex justify-end gap-4 pt-5 xl:pt-0 lg:pt-0">
          <Button
            type="button"
            className="border-custom-red border hover:border-custom-red w-full mt-3 xl:mt-0 lg:mt-0 sm:mt-3 md:mt-3 hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
            text="Cancel"
            onClick={handleCancel}
          />
          <Button
            type="submit"
            className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
            text="Save Changes"
          />
        </div>
      </form>
    </div>
  );
};

export default Document;
