import React, { useState, useEffect } from "react";
import useNetworkServices from "../../Networking/baseAPIManager";

const RedeemModal = ({ onClose }) => {
  const { WithDrawAmonut, GetProfile } = useNetworkServices();

  const [formData, setFormData] = useState({
    withdrawAmount: "",
    convertCash: false,
    reward_points: "",
    accountHolder: "",
    accountNo: "",
    bankName: "",
    ifscCode: "",
  });

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const profileResponse = await GetProfile();
        const profile = profileResponse.data.data;
        setFormData((prevData) => ({
          ...prevData,
          reward_points: profile.reward_points || "",
          accountHolder: profile.bankDetails?.accountHolderName || "",
          accountNo: profile.bankDetails?.accountNumber || "",
          bankName: profile.bankDetails?.bankName || "",
          ifscCode: profile.bankDetails?.ifscCode || "",
        }));
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProfileData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleWithdraw = async () => {
    const { withdrawAmount, convertCash, reward_points, accountHolder, accountNo, bankName, ifscCode } = formData;

    const withdrawalData = {
      convertCash,
      reward_points: +withdrawAmount,
      accountHolder,
      accountNo,
      bankName,
      ifscCode,
    };

    console.log("Final withdrawal data before sending:", withdrawalData);

    try {
      const response = await WithDrawAmonut(withdrawalData);
      console.log("Withdrawal successful:", response);

      onClose();
    } catch (error) {
      if (error.response) {
        console.error("Error processing withdrawal:", error.response.data);
      } else {
        console.error("Error processing withdrawal:", error.message);
      }
    }
  };
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4 text-center">Confirm Withdraw</h2>

        <div className="mb-4">
          <label className="block text-gray-700">Withdrawal CashFuse</label>
          <input
            type="number"
            name="withdrawAmount"
            value={formData.withdrawAmount}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded mt-2"
            placeholder="1000"
          />
        </div>

        {/* Checkbox for converting cash */}
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            name="convertCash"
            checked={formData.convertCash}
            onChange={handleChange}
            className="mr-2"
          />
          <label className="text-gray-700">Do you want to convert to cash?</label>
        </div>

        <div className="flex justify-between">
          <button className="px-4 py-2 border rounded text-red-500 border-red-500 hover:bg-red-100" onClick={onClose}>
            Cancel
          </button>
          <button onClick={handleWithdraw} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
            Withdraw
          </button>
        </div>
      </div>
    </div>
  );
};

export default RedeemModal;
