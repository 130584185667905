import React from "react";
import ReactApexChart from "react-apexcharts";

const CashfuseChart = ({ cashfuseData }) => {
  const transformedData = transformData(cashfuseData);

  const chartData = {
    series: transformedData,
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "28%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#E73921", "#025D89"],
      stroke: {
        show: true,
        width: 0.5,
        colors: ["transparent"],
        dashArray: 1,
      },
      xaxis: {
        categories: getAllMonthNames(),
      },
      yaxis: {
        labels: {
          show: false, // This hides the Y-axis labels entirely
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "bottom",
        horizontalAlign: "left",
        offsetX: 0,
      },
    },
  };

  return (
    <div className="overflow-x-auto">
      <div id="chart" className="min-w-[768px]">
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
      </div>
    </div>
  );
};

const getAllMonthNames = () => [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const transformData = (data) => {
  const allMonths = getAllMonthNames();

  return data.map((item) => {
    const monthDataMap = new Map();

    item.data.forEach((point) => {
      const [, month] = point._id.split("-");
      monthDataMap.set(parseInt(month), point.totalRewardPoints);
    });

    const fullData = allMonths.map((_, index) => {
      const monthIndex = index + 1;
      return monthDataMap.get(monthIndex) || 0;
    });

    return { name: item.name, data: fullData };
  });
};

export default CashfuseChart;
