import SubHeaderMenu from "./Sidebar/SubHeaderMenu";
import { useLocation } from "react-router-dom";
import useNetworkServices from "../Networking/baseAPIManager";
import { useEffect, useState } from "react";

export default function TermsConditions() {
  const location = useLocation();
  const [title, setTitle] = useState("user terms And Conditions");
  const [description, setDescription] = useState("");
  const [submittedContent, setSubmittedContent] = useState("");
  const { TreamCondation, GetTreams } = useNetworkServices();

  useEffect(() => {
    fetchTerms();
  }, []);
  console.log(submittedContent, "submittedContent");

  const fetchTerms = async () => {
    try {
      const response = await GetTreams();

      if (response.status === 200) {
        setSubmittedContent(response.data.data.description);
      }
    } catch (error) {
      console.error("Error fetching terms and conditions:", error);
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const content = {
      title: title,
      description: description,
    };

    try {
      const response = await TreamCondation(content);
      if (response.status === 200) {
        setSubmittedContent(description);
      }
      setDescription("");
      fetchTerms();
    } catch (error) {
      console.error("Error submitting terms and conditions:", error);
      alert("Failed to submit terms and conditions. Please try again.");
    }
  };

  const submittedLines = submittedContent.split("\n");
  const submittedHeading = submittedLines[0] || "";
  const submittedBulletPoints = submittedLines.slice(1);

  return (
    <>
      <div className="p-4" id="main">
        <h1 className="font-bold text-2xl text-slate-900 pb-5">
          <SubHeaderMenu location={location} />
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="description" className="block text-sm font-bold text-gray-700 mb-1">
              Adding the Terms & Conditions Heading section
            </label>
            <textarea
              id="description"
              rows="10"
              value={description}
              onChange={handleDescriptionChange}
              className="bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
              placeholder="Enter terms and conditions here...
              Heading..................................
              points..................................................................."
            />
          </div>

          <div className="xl:flex lg:flex md:flex flex sm:flex justify-end gap-4 pt-5 xl:pt-0 lg:pt-0 mb-5">
            <button
              type="submit"
              className="border-custom-red border hover:border-custom-red hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
            >
              Submit
            </button>
          </div>
        </form>

        <div className="mb-6">
          <div className="bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800">
            <div className="mb-4">
              <h3 className="font-semibold text-xl">{submittedHeading}</h3>
              <ul className="list-disc pl-5 mt-2">
                {submittedBulletPoints.map((point, index) => (
                  <li key={index} className="text-sm text-gray-700">
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
