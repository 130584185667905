import React, { useEffect, useState } from "react";
import SubHeaderMenu from "./Sidebar/SubHeaderMenu";
import { useLocation } from "react-router-dom";
import Button from "./Common/Button";
import { Table } from "./Common/Table/Table";
import useNetworkServices from "../Networking/baseAPIManager";
import RedeemModal from "./Common/RedeemModal ";
import moment from "moment";

export const Wallet = () => {
  const location = useLocation();
  const { Getwallet } = useNetworkServices();
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log(tableData, "tableData");
  const moment = require("moment");
  const dateHtml = (date) => moment(date).format("DD MMM YYYY");
  const formattedTransactionId = (row) => row._id.slice(-4);
  const formattedDate = (row) => moment(row.timestamp).format("DD MMMM YYYY");
  const formattedTime = (row) => moment(row.timestamp).format("hh:mm A");

  const columns = [
    { header: "S. No", accessor: "sNo" },
    { header: "Transaction ID", accessor: "shortId" },
    { header: "Date", accessor: "formattedDate" },
    { header: "Time", accessor: "formattedTime" },
    { header: "Bank Name", accessor: "bankName" },
    { header: "Account No", accessor: "accountNo" },
    { header: "Reward Points", accessor: "reward_points" },
    { header: "Balance", accessor: "balanceAfter" },
  ];

  const handleRedeemClick = () => {
    setIsModalOpen(true);
  };

  const balanceBefore = tableData?.redeemAmount || "N/A";

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await Getwallet();
        const formattedUsers = response.data.data.users.map((user, index) => ({
          ...user,
          sNo: index + 1,
          shortId: user._id.slice(-4),
          formattedDate: moment(user.timestamp).format("YYYY-MM-DD"),
          formattedTime: moment(user.timestamp).format("hh:mm A"),
        }));
        setTableData({ ...response.data.data, users: formattedUsers });
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };
    fetchTransactions();
  }, [Getwallet]);

  return (
    <div id="main" className="p-4">
      <h1 className="font-bold text-2xl text-slate-900 pb-5">
        <SubHeaderMenu location={location} />
      </h1>
      <div className="grid grid-cols-12">
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6 p-4 sm:border-r-[0px] xl:border-r-[1px] xl:border-b-[1px]">
          <div className="flex justify-between">
            <div className="dashboard_content">
              <h3 className="text-2xl font-bold text-gray-800 mb-2">CashFuse: {balanceBefore}</h3>
            </div>
            <div>
              <Button
                className="bg-custom-blue text-white font-bold py-2 px-4 rounded hover:bg-custom-blue2 transition duration-300"
                text={"Redeem Now"}
                onClick={handleRedeemClick}
              />
            </div>
          </div>
        </div>

        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-6 lg:pb-4 sm:pb-4 sm:border-0 lg:border-b-[1px] xl:col-span-6 xl:border-b-[1px] px-4">
          <div className="flex justify-between pt-4">
            <div className="dashboard_content">
              <h6 className="text-sm font-bold text-gray-600 mb-2">INR: 000</h6>
            </div>
          </div>
        </div>
      </div>
      <Table columns={columns} data={tableData?.users} />
      {isModalOpen && <RedeemModal onClose={() => setIsModalOpen(false)} />}
    </div>
  );
};
