import React from "react";

const TabsSecond = ({ tabs, setCurrentStep, currentStep, completedSteps }) => {


    return (
        <div className="container mx-auto lg:p-4 md:p-4 shadow-lg">
            <div className="flex flex-col items-center">
                <div className="w-full p-0 mt-3 mb-2">
                    <div className="bg-white rounded-lg lg:p-6 md:p-6 w-full">
                        {/* Progress Bar */}
                        <div className="relative">
                            <div className="w-full h-4 bg-gray-200 rounded-full overflow-hidden">
                                
                            </div>

                            {/* Progress Steps */}
                            <ul className="absolute inset-0 flex justify-between items-center">
                                {Array.from({ length: tabs.length }, (_, step) => (

                                    <li key={step} className="relative flex-1 flex justify-center">

                                        <div
                                            className={`w-8 h-8 rounded-full flex items-center justify-center ${currentStep === step + 1
                                                ? "bg-custom-red text-white"
                                                : completedSteps.includes(step + 1)
                                                    ? "bg-green-700 text-white"
                                                    : "bg-gray-300 text-gray-500"
                                                }`}
                                        >
                                            {step + 1}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Tab Content */}
                        <div className="mt-8">
                            <h2 className="text-2xl text-custom-red mb-4">
                                {tabs[currentStep - 1]?.title}
                            </h2>
                            {tabs[currentStep - 1]?.content}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TabsSecond;
