export const setGetLocalData = (key, data) => {

    console.log(data, key, "=============>>>>>>>>>>>>>>>")

    if (data) {
        sessionStorage.setItem(key, data);

    } else {
        sessionStorage.getItem(key);

    }
};


export const ClearLocalData = (key) => {
    console.log(key, "KeyClear")

    if (key) {
        // sessionStorage.removeItem(key);
    } else {
        sessionStorage.clear();
    }
};
