import React from "react";
import BG1 from "../../assets/images/AboutUs/bg1.png";
import LImg from "../../assets/images/img/Terms.png";
const PrivacyPolicy = () => {
  return (
    <>
      <div
        className="container m-auto mt-5 relative bg-no-repeat bg-cover rounded-lg text-white grid grid-cols-1 lg:grid-cols-2 items-center"
        style={{
          backgroundImage: `url(${BG1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Left Content Section */}
        <div className="p-6 sm:p-10 lg:p-12 m-auto text-center lg:text-left">
          <h2 className=" text-[28px] lg:text-[42px] sm:text-[32px] md:text-[32px] font-bold flex justify-center lg:justify-start">
            Privacy & Policy
          </h2>
          <p className="text-[22px] text-base  mt-4 mb-2">
            Get Rewarded For Doing Things You Love
          </p>
          <hr className="w-24 " />
        </div>

        {/* Right Image Section */}
        <div className="flex justify-center items-center p-6 sm:p-10 lg:p-12">
          <img src={LImg} alt="Privacy Policy" className=" " />
        </div>
      </div>
      <div className="container m-auto py-10">
        <h6 className="text-red-600 pb-5 text-xl">
          singhtek, accessible from https://singhtek.com/, one of our main
          priorities is the privacy of our visitors.
        </h6>
        <p className="text-[#857885]   mt-2">
          At{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
            <span className="font-bold text-[#025D89]">Fuse</span>,
          </span>
           we respect and
          value your privacy. This Privacy Policy outlines how we collect, use,
          and protect your personal information when you access and use our
          website, mobile application, and services. By using{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span>, you consent to
          the practices described in this policy. We are committed to ensuring
          the privacy and security of your data and complying with relevant
          privacy laws.
        </p>

        <p className="text-[#857885]  mt-2">
          <span className="font-bold">1. Information We Collect</span>
          <br />
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> collects both
          personal and non-personal information when you interact with our
          platform. The types of information we gather may include, but are not
          limited to, your name, email address, phone number, payment
          information, transaction details, IP address, browser information, and
          device type. This information is collected to provide and enhance our
          services, ensure smooth transactions, and offer personalized
          experiences.
        </p>

        <p className="text-[#857885]   mt-2">
          <span className="font-bold">2. How We Use Your Information</span>
          <br />
          The personal information we collect is used for various purposes,
          including but not limited to, processing transactions, managing your
          account, delivering promotional offers, sending service-related
          communications, and improving our platform’s functionality. We may
          also use your information for internal analytics, ensuring that the
          platform meets your needs effectively, and improving customer service.
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> may use the
          collected data to send periodic emails related to your transactions,
          new features, or promotional offers. You have the option to opt-out of
          these communications by adjusting your preferences or unsubscribing
          from the email list.
        </p>

        <p className="text-[#857885]   mt-2">
          <span className="font-bold">3. Data Sharing and Disclosure</span>
          <br />
          We respect your privacy and do not sell, trade, or otherwise transfer
          your personal information to third parties, except as outlined in this
          policy. However,
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> may share your
          information with trusted third-party service providers who assist us
          in operating our services, conducting our business, or servicing you.
          These third-party partners are contractually obligated to safeguard
          your information and use it solely for the purposes intended. In
          certain situations, we may disclose personal information in response
          to legal requirements, such as in compliance with a subpoena, court
          order, or other legal process. Additionally, we may disclose
          information to protect our rights, property, and safety, or the
          rights, property, and safety of others, including in the case of fraud
          prevention or investigation.
        </p>

        <p className="text-[#857885]   mt-2">
          <span className="font-bold">4. Data Security</span>
          <br />
          We take the security of your personal information seriously and
          implement industry-standard security measures to protect your data
          from unauthorized access, alteration, or disclosure.{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> utilizes
          encryption and secure servers to safeguard your sensitive information.
          Despite our best efforts, no security system is completely
          impenetrable, and we cannot guarantee the absolute security of your
          data. We recommend that you take necessary precautions, such as using
          strong passwords and ensuring that your account details remain
          confidential.
        </p>

        <p className="text-[#857885]   mt-2">
          <span className="font-bold">
            5. Cookies and Tracking Technologies
          </span>
          <br />
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> may use
          cookies, web beacons, and other tracking technologies to enhance the
          user experience, analyze usage trends, and improve our services.
          Cookies are small data files stored on your device that allow us to
          recognize your preferences and personalize your interactions with our
          platform. You can modify your browser settings to refuse cookies or
          alert you when cookies are being sent; however, some features of the
          platform may not function properly without cookies.
        </p>

        <p className="text-[#857885]   mt-2">
          <span className="font-bold">6. Third-Party Links</span>
          <br />
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span>’s platform may
          contain links to third-party websites, products, or services that are
          not controlled by us. These third-party sites have their own privacy
          policies, and we encourage you to review them before providing any
          personal information.{" "}
          <span>
            <span className="font-bold text-[#E73921]">Cash</span>
          </span>
          <span className="font-bold text-[#025D89]">Fuse</span> is not
          responsible for the content or practices of these external websites.
        </p>

        <p className="text-[#857885]  mt-2">
          <span className="font-bold">7. Retention of Personal Data</span>
          <br />
          We retain your personal information for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy, unless a longer
          retention period is required or permitted by law. Once your data is no
          longer needed, we will securely delete or anonymize it, ensuring that
          your privacy is maintained.
        </p>

        <p className="text-[#857885]   mt-2">
          <span className="font-bold">8. Your Rights and Choices</span>
          <br />
          Depending on your jurisdiction, you may have certain rights regarding
          your personal information, including the right to access, update, or
          delete the information we hold about you. If you wish to exercise any
          of these rights, please contact us using the details provided in this
          policy. Additionally, you may choose to opt out of receiving marketing
          communications from us at any time by using the unsubscribe feature in
          our emails or adjusting your notification preferences.
        </p>

        <p className="text-[#857885]   mt-2">
          <span className="font-bold">11. Contact Information</span>
          <br />
          If you have any questions or concerns about this Privacy Policy or how
          we handle your personal data, please contact us at:
          <br /> Email: [Insert Support Email]
          <br /> Phone: [Insert Support Phone Number]
          <br /> Address: [Insert Physical Address]
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
