import React, { useEffect, useState } from "react";
import Button from "../Common/Button";
import SubHeaderMenu from "../Sidebar/SubHeaderMenu";
import { useLocation, useNavigate } from "react-router-dom";
import useNetworkServices from "../../Networking/baseAPIManager";
import TextEditor from "../Common/TextEditor";
import { debounce } from "lodash";
const AddOffer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location?.state?.offerData;
  const { AddCashOffer, AddofferUpdate } = useNetworkServices();
  const [error, setErrors] = useState({});


  const [formData, setFormData] = useState({
    offerTitle: data?.offerTitle || "",
    storeOffer: data?.storeOffer || "",
    minimumPurchage: data?.minimumPurchage || "",
    validFrom: data?.validFrom || "",
    validTo: data?.validTo || "",
    offerDescription: data?.offerDescription || "",
    offerId: data?.offerId || "",
    Caseback: data?.Caseback || "",
    termsAndConditions: data?.termsAndConditions || "",
    offerId: data?._id || "",
  });

  const currentDate = new Date();
  const formatedCurrentDate = currentDate.toISOString().split("T")[0];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    const { name, value } = e.target;
    const newError = { ...error };
    delete newError[name];
    setErrors(newError);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!formData.offerTitle) {
      errors.offerTitle = "Offer Title is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.offerTitle)) {
      errors.offerTitle = "Offer Title can only contain text";
    }

    if (!formData.storeOffer) {
      errors.storeOffer = "Store Offer is required";
    } else if (isNaN(formData.storeOffer)) {
      errors.storeOffer = "Store Offer must be a number";
    }

    if (!formData.minimumPurchage) {
      errors.minimumPurchage = "Minimum Purchase Amount is required";
    } else if (!/^\d+$/.test(formData.minimumPurchage)) {
      errors.minimumPurchage = "Minimum Purchase Amount must be numeric";
    }

    if (!formData.validFrom) {
      errors.validFrom = "Start Date is required";
    }

    if (!formData.validTo) {
      errors.validTo = "End Date is required";
    } else if (new Date(formData.validTo) <= new Date(formData.validFrom)) {
      errors.validTo = "End Date must be after Start Date";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const submissionData = {
      ...formData,
      offerDescription: formData.offerDescription ? formData.offerDescription.replace(/<\/?[^>]+(>|$)/g, "") : "",
      termsAndConditions: formData.termsAndConditions ? formData.termsAndConditions.replace(/<\/?[^>]+(>|$)/g, "") : "",
    };

    try {

      const response = formData.offerId
        ? await AddofferUpdate(submissionData)
        : await AddCashOffer(submissionData);
      console.log("Response:", response);
      navigate("/cashFuseOffer");
    } catch (error) {
      console.error("Add Cash Offer error:", error);
      alert("An error occurred while adding the offer.");
    } 
  };

  const handleCancel = () => {
    setFormData({
      offerTitle: "",
      storeOffer: "",
      minimumPurchage: "",
      termsConditions: "",
      Caseback: "",
      validFrom: "",
      validTo: "",
      offerDescription: "",
      offerId: "",
      _id: "",
    });
  };

  const inputFields = [
    { name: "offerTitle", type: "text", label: "Offer Name", placeholder: "Offer name" },
    { name: "storeOffer", type: "text", label: "Store Offer", placeholder: "Offer in Cash Fuse" },
    {
      name: "minimumPurchage",
      type: "text",
      label: "Minimum Purchase Amount (Cash Fuse)",
      placeholder: "Minimum Purchase Amount (Cash Fuse)",
    },
    { name: "Caseback", type: "text", label: "Cashback", placeholder: "Cashback / Reward" },
    { name: "validFrom", type: "date", label: "Start Date", placeholder: "Start Date", min: formatedCurrentDate },
    { name: "validTo", type: "date", label: "End Date", placeholder: "End Date" },
  ];

  const handleEditorChange = debounce((field, content) => {
    setFormData((prev) => ({ ...prev, [field]: content }));
  }, 300);

  return (
    <div className="p-4" id="main">
      <h1 className="font-bold text-2xl text-slate-900 pb-5">
        <SubHeaderMenu location={location} />
      </h1>


      <form className="space-y-4 md:space-y-6 pt-6" onSubmit={handleFormSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
          {inputFields.map((field) => (
            <div key={field.name}>
              <label htmlFor={field.name} className="block mb-2 text-sm font-bold text-gray-900">
                {field.label}
              </label>
              <input
                type={field.type}
                name={field.name}
                id={field.name}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                placeholder={field.placeholder}
                value={formData[field.name]}
                onChange={handleChange}
                min={field.min}
              />
              {error[field.name] && <span className="text-red-500 text-sm">{error[field.name]}</span>}
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 xl:grid-cols-2 gap-7">
          <div className="w-full">
            <label htmlFor="termsAndConditions" className="block mb-2 text-sm font-bold text-gray-900">
              Terms / Conditions
            </label>
            <TextEditor
              value={formData.termsAndConditions}
              onChange={(content) => handleEditorChange("termsAndConditions", content)}
            />
          </div>
          <div className="w-full">
            <label htmlFor="offerDescription" className="block mb-2 text-sm font-bold text-gray-900">
              Offer Description
            </label>
            <TextEditor
              value={formData.offerDescription}
              onChange={(content) => handleEditorChange("offerDescription", content)}
            />
          </div>
        </div>

        <div className="flex justify-end gap-4 pt-5">
          <Button
            type="button"
            className="border-red-500 hover:bg-red-500 text-red-500 font-bold py-2 px-4 rounded-lg"
            text="Cancel"
            onClick={handleCancel}
          />
          <Button
            type="submit"
            className="bg-red-500 text-white font-bold py-2 px-4 rounded-lg"
            text="Save Changes"
          />
        </div>
      </form>
    </div>
  );
};

export default AddOffer;
