import React, { useState, useEffect } from "react";
import SubHeaderMenu from "./Sidebar/SubHeaderMenu";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "./Common/Button";
import { Table } from "./Common/Table/Table";
import useNetworkServices from "../Networking/baseAPIManager";
import { GrView } from "react-icons/gr";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Modal from "../Components/Common/Modal ";
import CashFuseCard from "../Components/Common/Cards/CahsFuseCard";
import OfferImg1 from "../assets/images/home/offer1.png";
import OfferImg2 from "../assets/images/home/offer22.png";
import OfferImg3 from "../assets/images/home/offer33.png";

const CashfuseOffer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { GetCashOffer } = useNetworkServices();
  const [tableData, setTableData] = useState([]);
  const [modalContent, setModalContent] = useState({});
  const [open, setOpen] = useState(false);
  const [rowsPerPage] = useState(13);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const customBtn = (data) => {
    const isEditable = data.status.toLowerCase() === "pending";
    return (
      <div className="flex gap-2">
        <GrView
          onClick={() => handleItemClick(data)}
          className="text-xl text-red-500 cursor-pointer hover:text-red-600"
        />
        <MdOutlineModeEditOutline
          onClick={() => handleEditClick(data)}
          className={`text-2xl cursor-pointer ${isEditable ? "text-gray-500 hover:text-gray-600" : "text-gray-300 cursor-not-allowed"
            }`}
          style={{ pointerEvents: isEditable ? "auto" : "none" }}
        />
      </div>
    );
  };

  const columns = [
    { header: "Offer Name", accessor: "offerTitle" },
    { header: "Store Offer", accessor: "storeOffer" },
    { header: "Min. Purchase Amount (Cash Fuse)", accessor: "minimumPurchage" },
    { header: "Cashback / Reward", accessor: "Caseback" },
    { header: "Start Date", accessor: "validFrom" },
    { header: "End Date", accessor: "validTo" },
    {
      header: "Status",
      accessor: "status",
      customBtn: (data) => (
        <span style={{ color: data.status.toLowerCase() === "pending" ? "#fcca03" : data.status.toLowerCase() === "accept" ? "green" : "red" }}>{data.status}</span>
      ),
    },
    { header: "Action", accessor: "action", customBtn },
  ];

  const handleItemClick = (data) => {
    const shopNames = data.merchantId?.businessDetils?.shopName;

    setModalContent({
      ...data,
      shopNames,
    });
    setOpen(true);
  };

  const handleEditClick = (data) => {
    if (data.status.toLowerCase() === "pending") {
      navigate("/addOffer", { state: { offerData: data } });
    } else {
      alert("Only pending offers can be edited.");
    }
  };

  const fetchCashFuseData = async () => {
    setIsLoading(true); // Show loader
    try {
      const response = await GetCashOffer();
      console.log(response.data.data, "get api data");
      setTableData(response.data.data);
    } catch (error) {
      console.error("Error fetching cash fuse offers:", error);
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  useEffect(() => {
    fetchCashFuseData();
  }, [GetCashOffer]);

  const addOffers = () => {
    navigate("/addOffer");
  };
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentTableData = tableData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(tableData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  return (
    <>
      {isLoading ? <div className="loader"></div>:
      <div id="main" className="p-4">
        <div className="flex justify-between">
          <h1 className="font-bold text-2xl text-slate-900 pb-5">
            <SubHeaderMenu location={location} />
          </h1>
          <Button
            onClick={addOffers}
            className="bg-custom-red text-white font-bold py-2 px-4 rounded hover:bg-custom-red3 transition duration-300"
            text="Add Offer"
          />
        </div>
        <Table columns={columns} data={currentTableData} />

        <Modal isOpen={open} onClose={() => setOpen(false)} title="Banner Image">
          <CashFuseCard
            shopName={modalContent?.shopNames || "Super Store gupta"}
            offerName={modalContent?.offerTitle || "Big Discount"}
            offerAmount={modalContent?.storeOffer || "10%"}
            backgroundImage={OfferImg1}
          />
        </Modal>
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-gray-100 text-black rounded disabled:opacity-50"
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-gray-100 text-black rounded disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>}
    </>
  );
};

export default CashfuseOffer;
