import React, { useState } from "react";
import { State, City } from "country-state-city";
import * as Yup from "yup";
import useNetworkServices from "../../../Networking/baseAPIManager";
import { showToast } from "../../Common/Toastify";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const { ContactUsData } = useNetworkServices();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    city: "",
    state: "",
    subject: "",
    message: "",
    sendUpdate: false,
  });

  const [errors, setErrors] = useState({});
  const [states, setStates] = useState(State.getStatesOfCountry("IN"));
  const [cities, setCities] = useState([]);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Name is required"),
    mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Invalid mobile number")
      .required("Mobile number is required"),
    email: Yup.string().email("Invalid email format"),
    city: Yup.string(),
    state: Yup.string(),
    subject: Yup.string(),
    message: Yup.string(),
  });

  const validate = () => {
    try {
      validationSchema.validateSync(formData, { abortEarly: false });
      return {}; // No errors
    } catch (error) {
      const formErrors = {};
      error.inner.forEach((err) => {
        formErrors[err.path] = err.message; // Map errors by field name
      });
      return formErrors;
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // If the user is typing in the fullName or mobile field, remove the error
    if ((name === "fullName" || name === "mobile") && errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleStateChange = (e) => {
    const selectedStateCode = e.target.value;
    const selectedState = states.find(
      (state) => state.isoCode === selectedStateCode
    );
    setFormData({
      ...formData,
      state: selectedState.name,
    });
    setCities(City.getCitiesOfState("IN", selectedStateCode));
  };

  const handleCityChange = (e) => {
    setFormData({
      ...formData,
      city: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate(); // Run validation
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // If no errors, proceed with form submission
      try {
        console.log(formData, "formdata");
        const response = await ContactUsData(formData);
        console.log(response?.data, "ResponseData");

        showToast("Form submitted successfully!", "success");
        setFormData({
          fullName: "",
          email: "",
          mobile: "",
          city: "",
          state: "",
          subject: "",
          message: "",
          sendUpdate: false,
        });
        setCities([]);
        setTimeout(() => {
          navigate("/");
        }, 3000);
        console.log("Form is valid:", formData);
      } catch (error) {
        console.error("Form submission error:", error);
        showToast("Form submission failed. Please try again.", "error");
      }
    }
  };

  return (
    <div className="w-full max-w-6xl mx-auto bg-white border border-[#F1F1F1] shadow-[0px 4px 10px rgba(13, 16, 45, 0.07)] p-10 rounded-lg">
      {/* Centered Header and Paragraph */}
      <div className="text-center mb-8 font-[inter]">
        <h1 className="text-[#1C244B] text-4xl font-semibold mb-4">
          Get in touch with us
        </h1>
        <p className="text-gray-600 text-lg ms-10">
          Get a Quote Immediately Upon Form Submission
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* First Column: Image */}
        <div className="md:col-span-1 flex items-start justify-center md:justify-start">
          <img
            src="./image/contactus.png"
            alt="Contact Us"
            className="w-full h-auto max-w-md"
          />
        </div>

        {/* Second Column: Contact Us Form */}
        <div className="md:col-span-2">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
              {/* Name Field */}
              <div>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-[#C5CAD1] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="fullName"
                  name="fullName"
                  type="text"
                  placeholder="Name"
                  value={formData.fullName}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[a-zA-Z\s]*$/.test(value)) {
                      // Allows only alphabets and spaces
                      handleInputChange(e);
                    }
                  }}
                />
                {errors.fullName && (
                  <p className="text-red-500 text-sm mt-1">{errors.fullName}</p>
                )}
              </div>

              {/* Email Field */}
              <div>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-[#C5CAD1] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>

              {/* Phone Field */}
              <div>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-[#C5CAD1] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="mobile"
                  name="mobile"
                  type="tel"
                  placeholder="Phone Number"
                  value={formData.mobile}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,10}$/.test(value)) {
                      // Allows only digits and ensures max length is 10
                      handleInputChange(e);
                    }
                  }}
                  maxLength={10} // Enforces max length at the HTML level
                />
                {errors.mobile && (
                  <p className="text-red-500 text-sm mt-1">{errors.mobile}</p>
                )}
              </div>

              {/* State Dropdown */}
              <div>
                <select
                  className="block appearance-none w-full bg-white border border-[#C5CAD1] text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="state"
                  name="state"
                  value={
                    states.find((state) => state.name === formData.state)
                      ?.isoCode || ""
                  }
                  onChange={handleStateChange}
                >
                  <option value="" disabled>
                    Select State
                  </option>
                  {states.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* City Dropdown */}
              <div>
                <select
                  className="block appearance-none w-full bg-white border border-[#C5CAD1] text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleCityChange}
                  disabled={!formData.state}
                >
                  <option value="" disabled>
                    Select City
                  </option>
                  {cities.map((city) => (
                    <option key={city.name} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Subject Dropdown */}
              <div>
                <select
                  className="block appearance-none w-full bg-white border border-[#C5CAD1] text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select Subject
                  </option>
                  <option>General Inquiry</option>
                  <option>Support</option>
                  <option>Feedback</option>
                </select>
              </div>
            </div>

            {/* Message Field */}
            <div className="mb-6">
              <textarea
                className="appearance-none block w-full bg-white text-gray-700 border border-[#C5CAD1] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="message"
                name="message"
                rows="4"
                placeholder="Message"
                value={formData.message}
                onChange={handleInputChange}
              />
            </div>

            {/* Checkbox for Updates */}
            <div className="flex items-center mb-6">
              <input
                type="checkbox"
                id="sendUpdate"
                name="sendUpdate"
                checked={formData.sendUpdate}
                onChange={handleInputChange}
                className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2"
              />
              <label htmlFor="sendUpdate" className="text-gray-700 text-sm">
                I agree to receive information, offers, and updates from
                Cashfuse*
              </label>
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-[#E73921] text-white font-bold py-2 px-8 rounded-lg
          hover:bg-[#C72D19] transition-colors duration-300"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
