import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const CustomerGraph = ({ cashfusebarData }) => {
  const [chartData, setChartData] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);
  useEffect(() => {
    if (cashfusebarData && cashfusebarData.newUserCount !== undefined) {
      const newUserCount = cashfusebarData.newUserCount || 0;
      const oldUserCount = cashfusebarData.oldUserCount || 0;
      const total = newUserCount + oldUserCount;

      setTotalUsers(total);
      // const newUserCount = cashfusebarData?.newUserCount || 0;
      // const oldUserCount = cashfusebarData?.oldUserCount || 0;
      // const totalUsers = newUserCount + oldUserCount;

      setChartData({
        series: [newUserCount, oldUserCount],
        options: {
          chart: {
            type: "radialBar",
            offsetY: 20,
            height: 350,
          },
          plotOptions: {
            radialBar: {
              startAngle: 0,
              endAngle: 350,
              hollow: {
                margin: 0,
                size: "60%",
                background: "transparent",
                dropShadow: {
                  enabled: true,
                  top: 0,
                  left: 0,
                  blur: 4,
                  opacity: 0.24,
                },
              },
              track: {
                background: "rgba(0, 0, 0, 0.1)",
                strokeWidth: "100%",
                dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.15,
                },
              },
              dataLabels: {
                show: true,
                name: {
                  fontSize: "16px",
                },
                value: {
                  fontSize: "14px",
                  formatter: (val) => `${val} Users`,
                },
                total: {
                  show: true,
                  label: "Total Users",
                  formatter: () => `${total}`,
                },
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: ["#035D89", "gray"],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
          labels: ["New Customer", "Old Customer"],
        },
      });
    }
  }, [cashfusebarData]);
  if (!chartData) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <div id="chart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="radialBar" height={350} />
      </div>
      <div className="flex justify-center mt-4 text-lg">
        <div className="mx-2">
          <span className="text-[#035D89] text-sm flex items-center">
            <div className="w-3 h-3 rounded-full bg-[#035D89] me-2"></div> New Customer
          </span>
          <p className="text-sm">{cashfusebarData?.newUserCount || 0}</p>
        </div>
        <div className="mx-2 ps-5">
          <span className="text-gray-500 text-sm flex items-center">
            <div className="w-3 h-3 rounded-full bg-[#e0e0e0] me-2"></div> Old Customer
          </span>
          <p className="text-sm">{cashfusebarData?.oldUserCount || 0}</p>
        </div>
      </div>
    </div>
  );
};

export default CustomerGraph;
