// Layout.js
import React from 'react'; 
import Header from '../Components/LandingPage/Header';
import Footer from '../Components/LandingPage/Footer'
const Layout = ({ children }) => {
    return (
        <>
          <Header/>
            <main>{children}</main>
            <Footer/>
          
        </>
    );
};

export default Layout;
