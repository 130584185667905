import React from "react";
import { QRCodeCanvas } from "qrcode.react"; // Adjusted import

const QRcode = ({ merchantData }) => {
  console.log(merchantData, "merchantData");
  const jsonData = JSON.stringify({
    email: merchantData?.email || "",
    phone: merchantData?.mobile || "",
    id: merchantData?._id || "",
    firstName: merchantData?.firstName || "",
  });

  let imageUrl = "";
  let imageSettings = {
    src: imageUrl,
    height: 50,
    width: 50,
    excavate: false,
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "40px" }}>
      <QRCodeCanvas
        value={jsonData}
        size={256}
        fgColor="#000000"
        bgColor="#FFFFFF"
        level="H"
        imageSettings={imageSettings}
        includeMargin={true}
      />
    </div>
  );
};

export default QRcode;
